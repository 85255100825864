.emoji--row {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 2px;
}
.emoji--row .holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 5.5px;
}

.single--header {
  font-weight: 700;
  font-size: 24px;
  color: #000;
  margin-bottom: 10px !important;
}

.emoji--row .each--emoji {
  padding: 6px;
  border-radius: 15px;
  background: #f5f5f5;
}
.emoji--row .each--emoji p {
  font-size: 12px;
}

.emoji--row .holder {
  cursor: pointer;
  transition: all 0.3s;
}

.emoji--row .holder.active {
  transform: scale(1.16);
  font-weight: 700;
}

.emoji--row .holder.active p {
  font-weight: 700;
  font-size: 12px;
}

.emoji--row .holder.active .each--emoji {
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px #eebe4f80;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  color: #000000;
  margin-bottom: 15px;
}

.feedback--section--two .section-two .text-area--box {
  position: relative;
}

.feedback--section--two .section-two .text-area--box p {
  position: absolute;
  bottom: 18px;
  right: 18px;
  font-size: 12px;
  line-height: 15px;
  color: #777779;
}

.feedback--section--two .section-two textarea {
  background: #ffffff;
  border: 1px solid #CCCCCC;
  width: 100%;
  height: 75px;
  border-radius: 0px;
  padding: 5px 10px;
  font-size: 14px;
}

.feedback--section--two,
.feedback--section--one {
  /* display: flex;
  flex-direction: column;
  gap: 25.5px; */
}

.single-chip--feedback {
  padding: 5.5px 12.33px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777779;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  transition: all 0.3s;
}

.single-chip--feedback.active {
  border: 1px solid var(--color-element-1);
  border-radius: 5px;
  color: #000000;
  position: relative;
}

.single-chip--feedback.active img {
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
}

.single-chip--feedback img {
  display: none;
  transition: all 0.3s;
}

.chip--section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
}
.emoji_container {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  margin-top: 5px;
}
.emoji_container .section-one {
  width: auto;
  margin-right: 15px;
}

