
:root{
    
    --primary-brokarage-color: #00ADBF;
    --secondary-brokarage-color: #A19276;
    --icon-color: #00ADBF;
    --currntweek: #00b70e;
    --past-week: #B1B1B1;
    --future-week: #a19276;
    --footer-bg-color: #ffffff;
    --footer-text-color: #bebcbc;

    /* exit realti-color code */
    /* --primary-brokarage-color: #008c9a;
    --secondary-brokarage-color: #232a34; */
}

/* exit realty module launch bg 
 .video_img {
    background: url(/public/images/exit-bro-course-bg.png);
}
*/

/* Custom brokarage color theme */

.cus-bro-theme .button-reset  svg circle, .cus-bro-theme .button-reset:hover svg circle#Ellipse_192, .cus-bro-theme .button-reset:hover svg circle#Ellipse_179, .cus-bro-theme .popover-container .side-image svg path {
    fill: var(--primary-brokarage-color);
}
.cus-bro-theme .button-reset  svg path, .cus-bro-theme .button-reset svg g circle {
    fill: #ffffff;
}
.cus-bro-theme .cal_iconLR .button-reset  svg circle, .cus-bro-theme .cal_iconLR .button-reset:hover svg circle, .cus-bro-theme .cal_iconLR .button-reset:hover svg circle#Ellipse_179, .cus-bro-theme .event_learn svg path, .cus-bro-theme #Icon_feather-map-pin path, .cus-theme-bro #clock-time-four{
    fill: var(--primary-brokarage-color);
}
.cus-bro-theme .button-reset svg g path, .cus-bro-theme .button-reset svg g line {
    stroke: #ffffff;
}
/* .cus-bro-theme .CircularProgressbar .CircularProgressbar-path{
    stroke: var(--icon-color) !important;
} */
.cus-bro-theme svg defs linearGradient stop:last-child {
    stop-color:  var(--icon-color);
}
.cus-bro-theme svg defs linearGradient stop:first-child {
    stop-color:  var(--icon-color);
}
.cus-bro-theme .rbc-today {
    border: 2px dashed var( --currntweek) !important;
    background: rgb(0 205 16 / 20%) !important
}
.cus-bro-theme  .non-registered-event {
    background-color: rgb(162 150 115 / 10%) !important;
    border: 1px solid var(--primary-brokarage-color);
}
.cus-bro-theme .rbc-header.rbc-today {
    background-color: var( --currntweek) !important;
}
.cus-bro-theme .btn-primary, .cus-bro-theme .btn-primaryBroker_comp, .cus-bro-theme .upgrate_colpeteBtn.active, .cus-bro-theme .btn-primary.active-page, .cus-bro-theme .btn-primary-complete, .cus-bro-theme .btn-primary-startNow, .cus-bro-theme .btn-primary-LR, .cus-bro-theme .cart-btn-primary, .cus-bro-theme .livestrembtn.streamlive .live-footer-menu-btn .btn-join.active, .cus-bro-theme .confirm.btn.btn-primary:hover, .cus-bro-theme .confirm.btn.btn-secondary, .cus-bro-theme .confirm.btn.btn-secondary:hover,
.cus-bro-theme .addfoldermodal .react-fine-uploader-gallery-file-input-container{
    background-image: unset;
    border: 2px solid var(--primary-brokarage-color) !important;
    border-image-source: unset !important;
    color: #fff;
    background: var(--primary-brokarage-color) !important;
    opacity: 0.8;
}
.cus-bro-theme .btn.btn-primary:disabled, .cus-bro-theme .btn-primaryBroker_comp:disabled, .cus-bro-theme .upgrate_colpeteBtn.active:disabled, .cus-bro-theme .btn-primary.active-page:disabled, .cus-bro-theme .btn-primary-complete:disabled, .cus-bro-theme .btn-primary-startNow:disabled, .cus-bro-theme .btn-primary-LR:disabled, .cus-bro-theme .cart-btn-primary:disabled{
    opacity: 0.4;
}
.cus-bro-theme .btn-primary:hover, .cus-bro-theme .btn-primary:focus .btn-primaryBroker_comp:hover, .cus-bro-theme .upgrate_colpeteBtn.active, .cus-bro-theme .btn-primary.active-page, .cus-bro-theme .btn-primary-complete, .cus-bro-theme .btn-primary-startNow:hover, .cus-bro-theme .btn-primary-startNow:focus, .cus-bro-theme .btn-primary-LR:hover, .cus-bro-theme .btn-primary-LR:focus, .cus-bro-theme .cart-btn-primary, 
.cus-bro-theme .livestrembtn.streamlive .live-footer-menu-btn .btn-join.active:hover
.cus-bro-theme .confirm.btn.btn-primary:hover, .cus-bro-theme .confirm.btn.btn-secondary:hover{

    opacity: 1;
}
.cus-bro-theme .login button{
    background: #fff !important;
    color: #1a3a4c;
}
.cus-bro-theme .my_courses .nav-tabs .nav-link.active, .cus-bro-theme .feedback-rating-card{
    border: 1.5px solid var(--primary-brokarage-color);
}
.cus-bro-theme  .openbtn span, .cus-bro-theme .scoresecfbbtm {
    background: var(--secondary-brokarage-color) !important;
}

.cus-bro-theme .openbtn{
   color: var(--secondary-brokarage-color); 
}
.cus-bro-theme #mySidenav .intro_navitem button.active {
    border-bottom: 3px solid var(--primary-brokarage-color) !important;
}
.cus-bro-theme .content-remind, .cus-bro-theme .content-SkipExplore{
    background: transparent;
    color: var(--secondary-brokarage-color);
    opacity: 0.8;
    border: 2px solid var(--secondary-brokarage-color);
    border-radius: 0.25rem;
}
 .cus-bro-theme .switch .slider.round  {
    background: transparent !important;
    border: 2px solid var(--secondary-brokarage-color) !important;
}
.cus-bro-theme  .switch input:checked + .slider {
    background: var(--secondary-brokarage-color) !important;
}
.cus-bro-theme .switch .slider:before{
    background-color: var(--secondary-brokarage-color);
}
.cus-bro-theme .switch input:checked +.slider:before{
    background-color: #fff;
}

.cus-bro-theme .content-remind:hover, .cus-bro-theme .content-SkipExplore:hover {
    opacity: 1;
    background: var(--secondary-brokarage-color);
    color: #fff;
}
.cus-bro-theme .home-slider-inner .btn-primary{
    border: 2px solid #fff;
    border-image-source: unset !important;
    color: var(--primary-brokarage-color);
    background: #fff !important;
}

.cus-bro-theme .carouseL_Iconn .ico__imG svg path, .cus-bro-theme .carouseL_IconnBY svg path, .cus-bro-theme .carouseL_Iconno2 svg path, .cus-bro-theme .cardicon_realtor svg path, .cus-bro-theme .slick-arrow.button-reset svg circle, .cus-bro-theme .info-bottm-card_nw svg path, .cus-bro-theme .delete-iamge-pointer svg path, .cus-bro-theme .img-profile_visbty svg g circle{
    fill: var(--icon-color);
}
.cus-bro-theme .invoiceIcons_modal svg rect, .cus-bro-theme .invoiceIcons_modal svg path, .cus-bro-theme .review-overview-card svg path{
    fill: var(--icon-color);
}
.cus-bro-theme .invoiceIcons_modal svg g line {
    stroke:  var(--icon-color);
}
 .cus-bro-theme .cardicon_realtor span {
    color: var(--secondary-brokarage-color);
}
.cus-bro-theme .sub_headingRealtor{
    color: var(--primary-brokarage-color);
}
.shop-cart span
.cus-bro-theme .newupgrade_btn{
    background: #fff;
    border: 2px solid var(--primary-brokarage-color);
}
.cus-bro-theme #courseCardCarousel .carousel-indicators .active, .cus-bro-theme #cartCardCarousel .carousel-indicators .active, .cus-bro-theme a.dropdown-item.customHover:hover, .cus-bro-theme .slick-dots li.slick-active button, .cus-bro-theme .customHoverdrop .customHover:hover{
    background: var(--primary-brokarage-color) !important;   
}
.cus-bro-theme  .input_searchfield, .cus-bro-theme .input_searchfield_search, .cus-bro-theme .input__field, .cus-bro-theme .cartContent input[type="checkbox"], .cus-bro-theme .input_field, .cus-bro-theme .input__field_auto_cmplt .MuiAutocomplete-root, .cus-bro-theme .input_searchfield_search-cart, .cus-bro-theme .border-info, .cus-bro-theme .instrubox{
    border: solid 2px var(--primary-brokarage-color);
    border-image-source: none !important;
}
.cus-bro-theme  .newupgrade_btn{
    background: #ffffff;
    border: 2px solid var(--primary-brokarage-color);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 39%);
}
.cus-bro-theme  .header-borderThin, .cus-bro-theme .pointshead {
    background: var(--primary-brokarage-color) !important;
    
}
.cus-bro-theme .points-table, .cus-bro-theme .pointer-history-events-table table.table.table-striped {
    border-bottom: 2px solid var(--secondary-brokarage-color) !important;
    border-image: unset;
}
.cus-bro-theme .label-heading_events, .cus-bro-theme .lable-heading_search, .cus-bro-theme .points-head-history h1, .cus-bro-theme .lable-heading_ethics.ethicsheadi, .cus-bro-theme .profile-banner h1{
    color: var(--primary-brokarage-color);
}
.cus-bro-theme .content_profile-pg span{
    color: var(--secondary-brokarage-color);
}
.cus-bro-theme .center_line, .cus-bro-theme .center_line_invoice {
    background: linear-gradient(90deg, var(--secondary-brokarage-color), var(--secondary-brokarage-color) 70.71%), linear-gradient(90deg, var(--secondary-brokarage-color), var(--secondary-brokarage-color) 70.71%), linear-gradient(90deg, var(--secondary-brokarage-color), var(--secondary-brokarage-color) 70.71%);
}
.cus-bro-theme .pointer-history-events-table thead:after{
    background: var(--secondary-brokarage-color);
    background-image: linear-gradient(to right, var(--secondary-brokarage-color), var(--secondary-brokarage-color),var(--secondary-brokarage-color), var(--secondary-brokarage-color));
}
.cus-bro-theme .weektable-acc .table-bordered thead tr .past-week{
    background-color: var(--past-week);
}
.cus-bro-theme .weektable-acc .table-bordered thead tr .active-week {
    background: var(--currntweek) !important;
}
.cus-bro-theme .weektable-acc .table-bordered thead tr .future-week {
    background: var( --future-week);
    opacity: 1;
}

.cus-bro-theme .dashboard-calendar .weektable-acc tbody tr .future-block {
    background-color: rgb(162 150 115 / 10%);
}
.cus-bro-theme .react-calendar__navigation, .cus-bro-theme .myComingEvent, .cus-bro-theme .react-calendar__navigation button:enabled:hover, .cus-bro-theme .react-calendar__navigation button:enabled:focus, .cus-bro-theme .thead_bdy_ED, .cus-bro-theme  .calendar-content .rbc-header{
    background: var(--secondary-brokarage-color);
}
.cus-bro-theme .react-calendar__navigation button:enabled:hover, .cus-bro-theme .react-calendar__navigation button:enabled:focus {
    background-color: var(--secondary-brokarage-color) !important;
}
.cus-bro-theme .tble_bdy i{
    color: var(--secondary-brokarage-color);
}
.cus-bro-theme .nav-item.show .dropdown-toggle.nav-link {
    border-bottom: 3px solid var(--primary-brokarage-color);
}
.cus-bro-theme .home-footer_new {
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
    border-top: 2px solid #000;
}
.cus-bro-theme .footer_cont p {
    color: #000;
}
.cus-bro-theme .footerLogo {
    border-right: 1px solid #000;
}
.cus-bro-theme .input__Field02 {

    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, var(--primary-brokarage-color), var(--primary-brokarage-color)) !important;
    border-image-source: linear-gradient( 90deg, var(--primary-brokarage-color), var(--primary-brokarage-color) );
  
}
.cus-bro-theme .video_img {
    background: url(/public/images/C21-brokarge-course-bg.png);
}
.cus-bro-theme .playbtnvideo svg path, .cus-bro-theme  .pdf-download-role svg path, .cus-bro-theme .videotimebox svg path, .cus-bro-theme .overview-card-section svg #clock-time-four,.cus-bro-theme .overview-card-section svg #Speed, .cus-bro-theme .toobox-page #Edit path,
.cus-bro-theme .actionbtnd.pe-0 svg path {

    fill: var(--icon-color);
}
.cus-bro-theme .toobox-page #Close g line, .cus-bro-theme .tablebox .img-fluid.closesrp g line {
    stroke:  var(--icon-color);
}
.cus-bro-theme .membershipBG_BasicPlan, .cus-bro-theme .points-one:before, .cus-bro-theme .points-one:after, .cus-bro-theme .points-theree:before, .cus-bro-theme .points-four:after, .cus-bro-theme .btn-join.active, .cus-bro-theme .events-popup,
.pagination-item.selected, .pagination-item:hover{
    background: var(--primary-brokarage-color); 
}
.cus-bro-theme .basic-plan .price-list-mem, .cus-bro-theme .points-count {
    background-color: var(--secondary-brokarage-color);
}
.cus-bro-theme .membershipCurrentPlans li, .cus-bro-theme .readmored, .cus-bro-theme .details-card_nw h5 a:hover, .cus-bro-theme .details-card_realtor h5 a:hover, .cus-bro-theme .order_id p:last-child, .cus-bro-theme .invoice_currency, .cus-bro-theme .telescope_text, .cus-bro-theme  .details-cards-oll1 .reset-button, .cus-bro-theme .hyperlink-to{
    color: var(--primary-brokarage-color);
}

.cus-bro-theme .dropdown-menu::-webkit-scrollbar-thumb, .cus-bro-theme .notification-nav .scroll-nofify::-webkit-scrollbar-thumb, .cus-bro-theme .leaderboad-graph::-webkit-scrollbar-thumb, .cus-bro-theme .recordings-roleplay::-webkit-scrollbar-thumb, .cus-bro-theme .dashboard-calendar .weektable-acc::-webkit-scrollbar-thumb {
    background: linear-gradient( 0deg, var(--primary-brokarage-color), var(--primary-brokarage-color) ) !important;
}
.cus-bro-theme .selected-bar, .cus-bro-theme .accordion-box .accordion-item-div .ratingbox {
    border: 4px solid var(--primary-brokarage-color);
    background: var(--primary-brokarage-color);
}
.cus-bro-theme .reviewbox-content.clicked{
    border: 1px solid var(--primary-brokarage-color) !important;
    background: var(--primary-brokarage-color) !important;
    color: #fff;
}

.cus-bro-theme .reviewbox-content.clicked .counttext{
    background: #fff;
    color: #474747;
}

.cus-bro-theme  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button:before, .cus-bro-theme button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button:before {
	background-image: url("data:image/svg+xml,%3Csvg id='Back' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30' height='30' viewBox='0 0 30 30'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23191919'/%3E%3Cstop offset='1' stop-color='%23191919'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ccircle id='Ellipse_57' data-name='Ellipse 57' cx='15' cy='15' r='15' fill='url(%23linear-gradient)'/%3E%3Ccircle id='Ellipse_179' data-name='Ellipse 179' cx='13' cy='13' r='13' transform='translate(2 2)' fill='%23fff'/%3E%3Cg id='Group_1344' data-name='Group 1344' transform='translate(22 15.002) rotate(135)'%3E%3Cpath id='Path_415' data-name='Path 415' d='M0 0H8V8' transform='translate(8.001 0) rotate(90)' fill='none' stroke='%23191919' stroke-linecap='round' stroke-linejoin='round' stroke-width='4'/%3E%3C/g%3E%3C/svg%3E");
}
.cus-bro-theme .fixed-loader svg path:first-child{
    fill: var(--primary-brokarage-color);
}
.cus-bro-theme .fixed-loader svg path:nth-child(2){
    fill: var(--secondary-brokarage-color);
}
.cus-bro-theme .fixed-loader svg path:nth-child(3), .cus-bro-theme  .upload-btn-sec .reviewbtn.rpsubmitbtn svg#Group_1507 path{
    fill: var(--primary-brokarage-color);
}
.cus-bro-theme .cart-wishox .closebtn-pfl g line, .cus-bro-theme .remove-img-wall svg g line, .cus-bro-theme .upload-image-gallery svg g line{
    stroke: var(--primary-brokarage-color);
}
.cus-bro-theme  .upload-image-gallery svg g path {fill: #fff;stroke: #fff;}

.cus-bro-theme .shop_content .shop_Icon_txt .icons-shop  svg path, .cus-bro-theme .border-det svg #Exclusion_2 {
    fill: var(--secondary-brokarage-color);
    opacity: 1;
}
.cus-bro-theme  .icons-shop svg{
    opacity: 1; 
}
.cus-bro-theme  .upload-image-gallery circle#Ellipse_57, .cus-bro-theme  .upload-image-gallery circle#Ellipse_179 {
    fill: var(--primary-brokarage-color);
}

.cus-bro-theme .upload-image-gallery:hover circle#Ellipse_179 {
    fill: var(--icon-color);
}
.cus-bro-theme .invoiceMainModal{
    background-image: linear-gradient(90deg, var(--secondary-brokarage-color), var(--secondary-brokarage-color));
}

.cus-bro-theme .react-calendar__tile--active:before,.cus-bro-theme .react-calendar__tile--active:focus:before,.cus-bro-theme .react-calendar__tile--active:hover:before {
    background-color: var(--currntweek) !important;
   
}

.cus-bro-theme .react-calendar__tile--active{
    color:#fff !important;
}
.cus-bro-theme .isLogin{
    background: #fff !important; 
    color: #191919;
}
.cus-bro-theme .footer_contthree a {
    color: #000;
}
.cus-bro-theme .skeleton-loader rect, .cus-bro-theme .pt-5.loader-launch svg rect, .cus-bro-theme .upload-btn-sec .reviewbtn.rpsubmitbtn svg g path {
    fill: #d9d9d9 !important;
}
