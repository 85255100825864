:root {
  --color-element-1: #00b7f1;
  --color-element-2: #ed156e;
}

@media screen and (min-width: 1500px) {

  :root,
  html,
  body {
    font-size: 1.125rem;
  }

  .nav-link,
  .bg-menu-lightWhite .nav-link.dropdown-toggle,
  .info-bottm-card_nw p,
  .info-bottm-card_nw span {
    font-size: 1rem;
  }

  .stick-top {
    top: 5.6rem;
  }

  .modal-lg {
    max-width: 1205px;
  }

  .event-block .btn-join {
    font-size: 0.75rem;
    padding: 0.275rem 0.2rem;
  }

  .popover-price {
    font-size: 0.7rem;
  }

  #courseCardCarousel .carousel-indicators,
  #cartCardCarousel .carousel-indicators {
    bottom: 48.1%;
  }
  .related-video-section-launchpage #courseCardCarousel .carousel-indicators {
    bottom: 42.1%;
}
  .cart-wishox #courseCardCarousel .carousel-indicators {
    bottom: 39.1%;
  }

  .videoplayer .video-js
  {
    width: 100%;
    height: 25rem;
  }

  .videolistbox .img-videosec,
  .videolistbox .img-videosec {
    height: 13.125rem;
  }

  .newsarticle.roleplay-page button,
  .role_upload_btn.edit_btn .btn-primary,
  .role_frm_upload_btn .btn-primary {
    padding: 7px 15px;
    border-radius: 5px;
  }

  .record-video .video-js {
    height: 25rem;
  }

  .recordings-roleplay {
    height: 30.8rem;
  }

  .reviews-data-table.table-report-layout .office_list .office-province:nth-child(4),
  .progess-report-tablesec.table-report-layout .office_list th.office-province:nth-child(5) {
    width: 17.9375rem;
  }

  .reviewvideoplayer .video-js {
    height: 24.525rem;
  }

  .feedback-rating-row {
    padding: 0.94rem 1rem;
  }

  .reviewvideoplayer .video-js {
    height: 28.525rem;
  }

  .content_prof.leadefbord {
    width: 30.5625rem;
    margin: 4.875rem auto auto;
  }

  .container-pie-chart {
    gap: 30px;
    grid-gap: 30px;
  }

  .form-role-play .input__field.commentfield {
    height: 7.5rem;
  }

  .learningc-countshow {
    font: normal normal bold 14px/18px Verdana;
  }

  .profile-left-sec #courseCardCarousel .carousel-indicators {
    bottom: 51.1%;
  }

  .videobox #courseCardCarousel.videoCardCarousel .carousel-indicators {
    bottom: 47% !important;
  }
  .row.videolistbox.rsp-video-cat-list #courseCardCarousel.videoCardCarousel .carousel-indicators {
    bottom: 45% !important;
  }
  .noticount {
    top: 10px;
  }
  .feedback-rating-card .css-1vooibu-MuiSvgIcon-root {
    width: 1.2em;
    height: 1.2em;
}
.checklist-section .card-width_cust_check .card-text-title {
  height: 2.6rem;
 
}
}

@media screen and (max-width: 290px) {

  .weekdays li,
  .days li {
    width: 12.2%;
  }
}

@media (max-width: 320px) {
  .actionicons-notes {
    position: absolute;
    top: 43px;
  }

  .card_image img {
    height: 12rem;
  }

  .header_img_left_ED h6 {
    width: 100%;
  }

  .row>* {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .premium-ic {
    width: 83%;
  }

  .drop {
    display: none;
  }

  .closebtn {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  /* .modal-content {
    height: 300px;
  } */
  .fade.alert-modal.modal.show .modal-content {
    width: 100%;
    height: auto;
  
}
  .congrtsmodal .modal-content {
    height: auto;
  }

  .first-time-onboarding {
    background-position: bottom;
  }

  .onboarding-modal-content p {
    width: 150%;
  }

  .first-time-onboarding h3 span {
    padding-left: 40px;
  }

  .card-width_cust {
    width: 100%;
  }

  .input-labl span {
    padding-left: 15px;
    font-size: 13px;
  }

  /* sidebar css */
  .sidenav {
    /* width: 100% !important; */
    height: 100%;
    position: fixed;
    /* z-index: 999 !important; */
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 116px;
    background-color: #fff !important;
    /* width: 45% !important; */
  }

  /* #mySidenav.sidenav.open {
    width: 366px;
    position: fixed;
  } */

  .main {
    margin-left: 0px !important;
  }

  #mySidenav .nav-tabs .nav-item button {
    font-size: 0.775rem;
  }

  .sidenav a {
    padding: 0px 7px;
    text-decoration: none;
    font-size: 17px;
    color: #000;
    display: block;
    transition: 0.3s;
  }

  .text-white h1 {
    font-size: 2.125rem;
    font-family: Verdana;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 120px;
    right: 9px;
    /* background: #1a3a4c; */
    border-radius: 100px;
    width: 25px;
    height: 25px;
    color: white;
    font-weight: 900;
  }

  .openNav {
    width: 100%;
    left: 0;
  }

  .closeNav {
    left: -100%;
  }

  .d-flex.badges-sections {
    justify-content: center;
  }

  /* sidebar css */
  .main-content {
    width: calc(100% - 4px);
    float: right;
    padding: 25px 30px;
    background: #fff;
    min-height: 100vh;
  }

  /* rahul code */
  .login-sec .col-md-4 {
    width: 80%;
    /* width: 241px; */
  }

  /* rahul code */
  .login h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  /* .img-fluid {
          width: 100%;
          height: auto;   
      } */

  .actionicons i {
    width: 20px !important;
    height: 20px !important;
    padding: 4px !important;
    font-size: 11px !important;
    margin-top: 22px !important;
  }

  .notification-contan span {
    display: none;
  }

  .usernameinitial {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }

  .vertical-tab-notify .nav-pills {
    width: 25%;
  }

  .tab.mailbox-left-cont {
    width: 100%;
    height: 14vh;
  }

  .tabcontent {
    width: 100%;
  }

  .left-menu li.active {
    border-radius: 10px 10px 10px 10px;
  }

  .main-content {
    width: calc(100% - 23px) !important;
    float: right;
    padding: 25px 30px;
    background: #fff;
    min-height: 100vh;
  }

  .course-left-sec {
    background-color: #fff;
    min-height: 33vh !important;
    padding: 50px 30px;
  }

  .projectlogo {
    font-size: 19px !important;
  }

  .inputsearch {
    min-width: 60px;
    border-radius: 10px 0px 0px 10px;
  }

  .plan-desc {
    margin-top: -8px;
  }

  .notesaccord .accordion-button {
    padding: 0.5rem 1.25rem 1.5rem 4.3rem;
  }

  .course-right-sec {
    padding: 2rem 1rem;
  }

  .details-cards-oll {
    box-shadow: 2px 2px 5px #3d3d3d !important;
  }

  .vertical-tab-notify .nav-pills .nav-link {
    margin-left: 0px;
  }

  /* Rahul code */
  .weekselector span::before {
    margin-right: 6px;
  }

  .weekselector {
    margin-left: 1%;
  }

  .steps {
    width: 180%;
  }

  .homeaccordian .accordion-body {
    margin-bottom: 28px;
  }

  .accordion-body {
    padding: 1rem 0.8rem;
  }

  .infosection {
    position: inherit;
    bottom: 0;
    margin: 0;
  }

  .needhelptog div {
    border-right: none;
  }

  button .canceltext i {
    margin-bottom: 4px;
  }

  .needhtext {
    border-radius: 50px;
  }

  .acc-btn-cont {
    padding: 0px 17px;
  }

  /* Rahul code */

  .drop {
    display: block !important;
    position: absolute;
    right: 5px;
  }

  .closebtn {
    display: block !important;
  }

  .refinefilter .form-select {
    min-width: 149px;
  }

  .dropselectinp .form-select {
    min-width: 75px;
    font-size: 12px !important;
  }

  .col-form-label {
    padding-left: 10px;
  }

  .my_carts {
    padding-right: 15px;
  }

  .wishlist-boxd .my_carts {
    padding-right: 0;
  }

  .open {
    margin-right: 6px !important;
  }

  .my_order .cart-description-ex-border,
  .my_order .cart-description-ex {
    flex-direction: column;
  }

  .my_order .order-wrapper .image-cart {
    width: 100%;
    height: 100%;
  }

  .my_order .order-wrapper .desc-content {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }

  .my_order .desc-wrap.content-cart {
    padding-left: 0;
  }

  .desc-content {
    width: 70%;
    margin-left: 0px;
    padding-left: 15px;
    margin-top: 15px;
  }

  .my_order .bg-content {
    flex-direction: column;
  }

  .my_order .order-cart {
    width: 100%;
    margin-top: 20px;
  }

  .my_order .order-content {
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .setup-password .right-space {
    margin-right: 0 !important;
    width: 100%;
  }

  .addTocart_btn {
    margin-top: 0px;
  }

  .addTocart_btn button {
    top: 12px;
    left: -7px;
  }

  .Cart_summery {
    margin-top: 150%;
  }

  .card-width_cust {
    width: 100% !important;
  }

  .join {
    left: 10%;
  }

  .cc-bg-colorr .join {
    width: 19px;
    font-size: 7px;
  }

  .cc-bg-colorr .Register {
    width: 26px;
    font-size: 6px;
    left: 21%;
  }

  .larng_point {
    width: 100%;
    margin-left: 0px;
  }

  button.btnDetails {
    width: 50%;
  }

  img.image {
    width: 80%;
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
  }
  .popup-home,
  .popup-home02,
  .popup-home03, .card.popup-home03.shimmerbox:first-child {
    position: relative;
    top: 0;
    width: 94% !important;
    right: 0;
    left: 0;
    margin: 10px auto;
    border-radius: 4px;
  }

  .mainHEADING {
    font-size: 16px;
  }
  .calsec {
    padding-top: 15px;
}
#mySidenav.sidenav {
  width: 100% !important;
}
  .banner-text {
    left: 15px;
    bottom: 20px;
    width: 92%;
  }

  .pt-5g {
    padding-top: 1.75rem;
  }

  .d-flex.My_Dashboard.New_My_Dashboard.pb-3 {
    padding-top: 10px !important;
  }

  .John_section.New-dashboard.gap-top-bottom,
  .gap-top-bottom.New-dashboard.last_back_color {
    padding-top: 2rem;
  }

  /* .home-footer_new {
    margin-top: 80px;
  } */

  .col-md-8.jn-section.mt-1 {
    margin-top: 15px !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .mgb .profile_user-list {
    height: auto;
  }

  /* .slider-course-listing .slick-slide {
    width: 100% !important;
  } */

  .calen-section {
    margin-top: 1.5rem !important;
  }

  .d-flex.cal_iconLR {
    margin-bottom: 10px;
  }

  .d-flex.My_Dashboard.New_My_Dashboard {
    margin-bottom: 10px;
  }

  .center_line {
    margin-top: 3rem !important;
  }

  .main__contrR {
    justify-content: center;
    padding-top: 0;
  }

  .John_section.New-dashboard.mt-4.gap-top-bottom img {
    max-width: 100%;
    height: auto;
  }

  .John_section.New-dashboard.mt-4.gap-top-bottom .col-md-4.mt-1 {
    text-align: center;
  }

  .slider-card {
    position: absolute;
    top: 1%;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 8;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .footer_cont {
    padding-left: 0;
    align-items: flex-start;
  }

  .footer_cont p {
    margin-bottom: 0;
    text-align: center !important;
  }

  .footerLogo {
    width: 100%;
    justify-content: center !important;
  }

  .dashboard-calendar .weektable-acc {
    overflow: auto;
  }

  .dashboard-calendar.New-dashboard {
    overflow-x: hidden;
  }

  /* .image-login img {
    height: auto;
    width: 100%;
} */
  .header_img_left h6 {
    width: 100%;
    font-size: 1.2rem;
  }

  .main_realtor h6 {
    font-size: 1.188rem;
    padding-top: 0 !important;
  }

  /* #scorm_player {
    height: auto !important;
  } */

  .image-login,
  .login-sec .header-border {
    height: 100%;
    position: static;
  }

  .header_img_left {
    text-align: center;
  }

  .profile-left-sec .input_field {
    width: 100%;
  }

  .desc-content {
    width: 62%;
    margin-left: 0px;
    padding-left: 10px;
  }

  .my_cart .cart-description {
    flex-direction: column;
  }

  .my_cart .desc-content {
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
  }

  .my_cart .image-cart {
    width: 100%;
  }

  .wrapper-desc {
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .desc-content p {
    font-size: 13px;
  }

  .cart-timer p,
  .cart-timer p:last-child {
    font-size: 12px !important;
  }

  .content-cart h2 {
    font-size: 20px;
  }

  .inner-content {
    flex-wrap: wrap;
  }

  .cart-icon.inner-content.cart_discount {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .shop-cost {
    font-size: 10px;
    width: 100%;
    text-align: right;
  }

  .my_cart .shop-cost p:first-child {
    text-align: left;
  }

  /* .desc-content p {
    font-size: 12px;
  } */

  .payment p:last-child {
    font-size: 10px;
  }

  .Payment-container .row {
    width: 100%;
    margin: 0;
  }

  .cart-content {
    width: 100%;
  }

  .cart-content p {
    width: 100%;
  }

  .cart-wrapper .inner-total p {
    width: auto;
  }

  .cart-wrapper .cart-btn {
    width: 100%;
    margin-top: 15px;
  }

  .desc-wrap {
    margin-top: 10px;
    justify-content: space-between;
  }

  .my_cart .desc-wrap.content-cart {
    padding-left: 0;
  }

  .cart-wrapper {
    padding-top: 36px;
  }

  /* .login-text {margin-top: 0.9375rem !important;} */
  .login-text {
    padding: 15px 20px 15px;
  }

  .ttxt p {
    margin-left: 0 !important;
  }

  .login h3 {
    margin-top: 0.725rem;
    margin-bottom: 1.25rem;
  }

  .mb-2.login-msg.h-16 {
    height: auto;
  }

  .login .form-row {
    margin-top: 2.125rem;
  }

  .footer-title {
    display: block !important;
    position: relative;
    margin-top: 1.25rem;
    text-align: right;
    bottom: 1rem;
  }

  .ttxt img {
    margin-bottom: 26px;
  }

  .certify-table {
    overflow-x: auto;
  }

  .input_field-certify {
    width: 100%;
  }

  .rbc-calendar .rbc-btn-group:last-child {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  /* 
  .rbc-calendar {
    width: 700px !important;
  } */
  .calendar-content .rbc-header {
    padding: 6px;
  }

  .rbc-row.rbc-time-header-cell .rbc-header {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
  }

  .rbc-row.rbc-time-header-cell .rbc-button-link {
    font: normal normal bold 16px/22px Verdana;
  }

  .calendar-content .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popover-price {
    font-size: 0.7rem;
  }

  .rbc-toolbar .rbc-toolbar-label {
    width: 100%;
    margin-top: 10px;
  }

  .react-calendar {
    width: 100% !important;
  }

  .pointsbox-table {
    overflow-x: auto;
  }

  .membershipplanmob .row.no-wrap {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .membershipplanmob .row.no-wrap .price-list {
    width: 75%;
  }

  .stick-top {
    top: 85px;
  }

  .mobclassfluid {
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  /* .footerLogo {
    border-right: unset;
  } */
  .certificate-sec {
    margin-right: 0;
    width: 100%;
  }

  /* .certificate-section {
    width: 100%;
  } */
  .responsive-carousel .cart-body {
    flex-wrap: wrap;
  }

  .rbc-calendar .d-flex.justify-content-between.align-items-center {
    flex-wrap: wrap;
  }

  .calscroll .calendar-label-title {
    display: block;
    width: 100%;
    text-align: left;
    margin-top: 0rem;
  }

  .input-text.cal-flter-box {
    width: 100%;
  }

  .left-btn-cl-box .setup-button {
    padding: 0.375rem 0.93rem !important;
  }

  .left-btn-cl-box {
    flex-wrap: wrap;
  }

  .rbc-calendar .d-flex.justify-content-between.align-items-center>.d-flex {
    margin-top: 1rem;
    justify-content: flex-end !important;
    width: 100%;
  }

  .next-btn-center {
    justify-content: flex-end !important;
  }

  .requesthead {
    justify-content: space-between;
  }

  .accpet-box-btn-list {
    width: 55%;
  }
  .modalassign #example-custom-modal-styling-title, .modal-dialog-centered .modal-title.h4 {
    font: normal normal bold 17px/22px Verdana;
  }
  .input-text.position-relative.d-flex.res-btn-rsp {
    width: calc(100% - 55%);
  }

  .table-responsive1.rsp-team-lead-table {
    margin-bottom: 2.25rem;
  }

  .pfl-section .my_carts {
    padding-right: 0;
  }

  .processnpxf .col-md-6 {
    width: 100%;
  }

  .processnpxf .statusshowsec p {
    margin-top: 5px;
  }

  .processnpxf .statusshowsec {
    padding-left: 0px !important;
  }

  .notes-text p,
  .favorites-tab-content.fav-text p {
    margin-left: 0.413rem;
    font-family: verdana;
    padding: 0px 0px;
  }

  .note-thumb {
    margin: 0px;
  }

  /* .notes-box {
  padding: 5px 0px 5px 0px;
} */
  #my-favourites .fav-title-index.thumb-image {
    padding-left: 0;
  }

  #my-favourites .fav-delete {
    padding-right: 0;
  }

  #my-favourites .fav-delete .bg {
    padding-right: 0;
  }

  .notes-box .notes-desc {
    margin-left: 0;
    padding: 5px;
  }

  #deleteIcons0 .bg.cursor-pointer {
    margin-bottom: 10px;
    padding: 0;
  }

  .edit_btn button {
    padding: 3px 15px;
  }

  .table-report-layout .col-7 {
    flex: 0 0 auto;
    width: 100% !important;
    margin-bottom: 20px;
  }

  .table-report-layout .tablebox {
    overflow-x: auto;
  }

  .table-report-layout .table.office_list {
    table-layout: fixed;
  }
  .learnigpathsectio .my_courses .nav-item {
    width: 45%;
}
  .table-report-layout .office-province {
    width: 225px;
  }

  .table-report-layout .table> :not(caption)>*>* {
    word-break: break-word;
    vertical-align: top;
  }

  .status-updt.form-group.input-text {
    border-left: none;
    padding: 6px 0;
  }

  .table-report-layout .button-reset.downbtnsreport {
    padding-right: 0;
    margin-top: 20px;
  }

  .asset-library-sec {
    padding-top: 2.25rem;
  }

  .fade.alert-modal.modal.show {
    padding: 15px;
  }

  .no-session-found .notfime {
    padding: 0.22rem 2.625rem;
  }

  .my_order .order-wrapper .btn-order {
    padding: 6px;
  }

  .my_order .pagination-container {
    overflow-x: auto;
    padding-left: 0;
  }

  #scorm_player {
    width: 100% !important;
    min-height: 300px !important;
  }

  .invocust_container {
    padding-left: 10px;
  }

  .invoice_main_modal h6 {
    font-size: 18px;
    padding-left: 10px;
  }

  .invoice_cust_container.billed-popup .invSectionMain {
    flex-direction: column;
  }

  .billed-popup .invSectionOne {
    width: 100%;
  }

  .invoice_cust_container {
    width: 90%;
  }

  .invoice-modal {
    padding-left: 0 !important;
  }

  .privacy-termspage .row {
    flex-direction: column-reverse;
  }

  .privacy-termspage .img-fluid.privacy-rghypos {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
  }

  .row.showcart .shop-details {
    flex-direction: column;
    width: 100%;
  }

  .row.showcart .shopping-price {
    margin-bottom: 10px;
  }

  .accpet-box-right {
    margin-top: 10px;
  }

  .accpet-box-btn-list {
    text-align: left;
  }

  .fload_right_wishlist {
    margin-top: 10px;
  }

  .cart_discount .discount-tile {
    padding-left: 0;
    padding-right: 0;
  }

  .invoice-modal .modal-content .modal-body {
    /* width: 100% !important; */
    margin-left: 0 !important;
    padding-left: 0;
  }

  .invoice-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
  }

  .invoice-modal .modal-content {
    height: 100%;
    overflow-x: auto;
  }

  /* .invoice-modal .invSectionMain {
  flex-direction: column;
} */
  /* .invoice-modal .invSectionOne {
  width: 100%;
}
.invoice-modal .invSectionTwo {
  width: 100%;
}

.invoice-modal .invSectionOneExtra, .invoice-modal .invTotal_Amont {
  width: 100%;
}
.invoice_cust_container.paymentdis .invSectionMain {
  flex-direction: row;
} */
  .pagination-item {
    padding: 0 5px;
    justify-content: center;
  }

  .dashboard-calendar .weektable-acc .event-tile-home {
    height: 1.98rem;
  }

  .invoiceIcons_modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: #fff;
    width: 100%;
    padding: 5px 0;
  }
  .desktop_calendar_section{
    display:none !important;
  }
  .invoiceIcons_modal img {
    margin: 5px;
  }

  .pointsbox .table-poits-row {
    align-items: flex-start !important;
  }

  .order-placed {
    width: 100%;
  }

  .order-total_wrapper .order_id {
    margin-top: 6px;
  }

  .charheading {
    margin-top: 0.625rem;
  }

  .row.boxes .col-md-4 {
    margin-bottom: calc(var(--bs-gutter-x) * 1);
  }

  .myrecordings {
    margin-top: 0.8rem;
  }

  .text-box-rs {
    width: calc(100% - 40%);
  }

  .button-sec {
    width: 40%;
  }

  .progress-report-data .row,
  .report-graphname .row {
    gap: calc(var(--bs-gutter-x) * 1);
  }

  .modalassign>.modal-content {
    height: auto;
  }

  .modal-90w {
    width: auto;
  }

  .crateteamform {
    flex-wrap: wrap;
  }

  .crateteamform .coose_form {
    flex-basis: 100%;
  }

  .crateteamform>div {
    flex-basis: 47%;
  }

  .mob-res {
    flex-wrap: wrap;
    gap: 1rem !important;
  }

  .points-four .tootip-text {
    left: -6px;
    bottom: 32px;
  }

  .side-calender {
    margin-top: 1.25rem;
  }

  .roleplay-page.pt-6 {
    padding-top: 3.25rem;
  }

  .reviewvideoplayer .video-js {
    height: 15.4rem;
  }

  .myrole-feedback {
    margin-top: 1.3rem;
  }

  .overview-card-section {
    margin-top: 1.3rem;
  }

  .box-rating-rolplay.d-flex {
    margin-top: 13px;
  }

  .sentimant-box .ratings-role strong {
    font-size: 1rem;
  }

  .sentimant-box .ratings-role {
    gap: 0px;
    grid-gap: 0px;
  }
  section.reviewoverview.door-knowking  .headfrf {
    font-size: 1.1rem;
}
  .feedtitle {
    margin-bottom: 0.3rem;
  }

  .fbsponly .video-box img {
    width: 100%;
    height: 9.125rem;
    object-fit: cover;
  }

  .edit_btn button {
    width: 10rem !important;
  }

  .video-tiel {
    flex-direction: column;
  }
  .search-section-toolbox .col-md-3.search__input {
    margin-top: 1.25rem;
  }
  
  .search-section-toolbox .col-md-3.search__input .form-select.input_searchfield {
    margin-bottom: 0;
  }
  .invalidemailshowfp {
    flex-direction: column;
  }
  .courspop {
    flex-wrap: wrap;
}

.courspop .taxttitle-left {
    width: 100%;
}
.checklistboxdata .usr-title {
  font: normal normal bold 16px/20px Verdana;
}
.main_realtor {
  margin-bottom: 1rem;
  padding-top: 0.5rem;
} 
.side-navbar .nav-side-title {
  width: calc(100% - 40px);
}
#openbtnNav{
  display: block;
}
.nav.side-nav-custom.flex-column.w-100.opennav {
  height: 0;
  opacity: 0;
  transition: .5s linear all;
}

.nav.side-nav-custom.flex-column.w-100 {
  height: 100%;
  transition: .5s linear all;
  opacity: 1;
  display: block !important;
}

.side-navbar .side-nav-custom .nav-link span {
margin: 0 !important;
}
.side-navbar{
border-right:none;
padding-right: 0;

}
}

@media (min-width: 320px) and (max-width: 576px) {
  button.needhelpbtn {
    background: none repeat scroll 0 0 #1a3a4c;
    position: fixed;
    top: 150px;
    z-index: 2;
    border-radius: 10px 0px 0px 10px;
    font-size: 19px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 30px;
  }

  .boxslidersp .learn_image-card img {
    width: 100%;
  }

  .needhcontainer {
    padding: 22px;
  }

  .userinfo-bg {
    margin-top: 45px;
  }

  .setup-button {
    padding: 0.375rem 1.83rem !important;
  }
  .desktop_calendar_section{
    display:none !important;
  }
  .recordingPopup .MuiDialogContent-root ul li a {
    width: 260px;
    min-width: 260px;
  }
}

@media (min-width: 320px) and (max-width: 767.9808px) {
  .accordion-item .accordion-button {
    margin-top: 3px;
  }

  .back-rgba {
    width: 246px;
  }

  .back-rgba h1 {
    padding-left: 118px;
  }

  .my_courses .nav-item {
    width: auto;
  }

  .my_courses .tab-content {
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .login p {
    /* font-size: 1.3rem; */
  }

  .coursepage_cn h1 {
    line-height: 1;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .home-footer {
    margin-top: 65px !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {

  .revisit-btn,
  .continue-btn,
  .locked-btn {
    position: relative;
    bottom: 5px;
  }

  .course-details-icons i {
    width: 20px;
    height: 20px;
    padding: 5px;
    font-size: 11px;
  }

  .p {
    text-align: justify;
  }

  /*shifted - 320px to 767.9808px  */
  /* .details-course-desp{position: relative; left: 12px; width: calc(100% - 40px);} */
  .home-footer {
    position: relative;
    top: 85px;
  }

  /* .certificate-section img {
    width: 70px;
    height: auto;
    max-width: 100%;
  } */

  /* .next-course-btn {
    position: absolute;
    left: 26%;
  } */

  .day_btn,
  .month_btn,
  .week_btn {
    width: 14% !important;
  }

  /* .month_btn{width: 21%!important;}
                  .week_btn{width: 21%!important;} */
  .cc-header {
    font-size: 10px !important;
  }

  .custom-c-column {
    font-size: 10px;
  }

  .col-md-5.actionicons {
    position: relative;
    top: 11px;
  }

  .tiles-view-course .col-md-4 {
    padding: 0 15px;
  }

  /* rahul */

  .day_btn,
  .week_btn,
  .month_btn {
    height: 30px !important;
    font-size: 12px !important;
  }
}

@media (min-width: 320px) and (max-width: 1096px) {
  .cc-body.cc-leftsection_cust {
    font-size: 12px;
    position: relative;
    right: 22px;
  }
}

@media (min-width: 320px) and (max-width: 359px) {
  .cc-body.cc-leftsection_cust {
    font-size: 10px;
    position: relative;
    right: 22px;
  }

  .cc-leftsection_cust label {
    font-size: 10px !important;
    left: 4%;
    /* text-align: center !important; */
    width: 9% !important;
  }
}

@media (min-width: 320px) and (max-width: 767.9808px) {
  .details-course-desp {
    position: relative;
    left: 24px;
    width: calc(100% - 40px);
  }
}

/* -----end---finrac */

/* ----start---descovery-text-article--- */

@media (min-width: 320px) and (max-width: 768px) {
  .getCertified .setup-button {
    padding: 0.375rem 0.5rem !important;
  }

  .getCertified.buutndf {
    justify-content: center;
    padding-right: 0;
    padding-top: 10px;
  }

  .search__input .input_searchfield_search,
  .events_section #search {
    width: 100%;
  }

  .tiles-view-course .col-md-4 {
    padding-left: 40px;
  }

  .iconLP02 {
    padding-left: 10px;
  }

  .revisit-btn,
  .continue-btn,
  .locked-btn {
    position: relative;
    bottom: 5px;
    left: 24px;
  }

  .input-lear .close-search-text {
    right: 10px;
  }

  .weektable-acc .table.table-bordered {
    table-layout: fixed;
  }

  .weektable-acc .table-bordered thead tr th {
    width: 125px;
  }

  .weektable-acc tbody tr td {
    font-size: 0.69rem;
  }

  .bannermodule .slick-dots {
    bottom: 0px !important;
    position: relative !important;
  }

}
@media only screen and (max-width:768px){
  .vjs-icon-fullscreen-enter:before, .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
    background-size: 12px !important;
}
.vjs-icon-picture-in-picture-enter:before, .video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before {
  background-size: 15px !important;
}
button.vjs-menu-button-popup span:before {
  background-size: 20px !important;
}
}
@media (min-width: 340px) and (max-width: 767.9808px) {
  .user-show-sec img {
    width: 100%;
  }

  .card-width_cust {
    width: 100%;
  }

  img.image {
    height: 100px;
    width: 100px;
  }

  .color_main,
  .elearn h6,
  .point p,
  .eventlist li span {
    font-size: 12px;
  }

  .icon {
    font-size: 20px;
  }

  button.btnDetails {
    width: auto;
  }

  .point p {
    padding-right: 0px;
  }

  ul.eventlist li i {
    right: 8px;
    top: 30px;
  }

  ul.eventlist li {
    padding: 5px;
  }

  .price-list {
    width: calc(100% - 40px);
  }

  .attention-btn {
    position: relative;
    bottom: 13px;
  }

  .atten_pop {
    margin-bottom: 10px;
  }

  .accordion-item:first-of-type .accordion-button {
    margin-top: 3px !important;
  }

  .coursepage {
    height: 200px;

    background-position: top;
  }

  .product_tb_bg {
    margin-top: 525px;
  }
}

@media (min-width: 340px) and (max-width: 400px) {
  ul.eventlist li i {
    top: 61px;
  }

  .circle_popup {
    margin-left: 15px;
  }

  .userinfo-bg span {
    font-size: 17px;
  }
}

@media (min-width: 360px) and (max-width: 428px) {
  .actionicons-notes {
    z-index: 1;
    position: absolute;
    right: 1px;
    bottom: 8px;
  }

  /* #mySidenav.sidenav.open {
    width: 100% !important;
    position: fixed;
    padding-top: 0;
    margin-top: 0 !important;
    z-index: 9;
    top: 75px;
  } */
}

@media (min-width: 360px) and (max-width: 1096px) {
  .cc-margin_btn {
    margin-left: 12.6% !important;
  }

  .cc-leftsection label {
    position: relative;
    left: 0% !important;
    font-size: 12px;
  }

  .cc-header {
    font-size: 11px !important;
  }

  /* shift to max-575px */
  /* .cc-bg-colorr p{font-size:8px!important; line-height: 10px!important;} */
}

@media (min-width: 375px) and (max-width: 428px) {
  /* .next-course-btn {
    position: absolute;
    left: 31%;
  } */
}

@media (min-width: 375px) and (max-width: 380px) {
  .home-footer {
    margin-top: 142px !important;
  }
}

@media (min-width: 393px) and (max-width: 395px) {
  .home-footer {
    margin-top: 182px !important;
  }
}

@media (max-width: 399.9808px) {
  .button.btn {
    left: 0px !important;
  }

  .product_tb_bg {
    /* background-color: #e8ebed;
                                  padding: 0; */
    margin-top: 488px !important;
  }
}

@media (min-width: 404px) and (max-width: 576px) {
  .finishit-btn {
    margin: 0px 0px 0px 59%;
  }

  /* .profile-left-sec {
    min-height: 72vh;
  } */

  ul.eventlist li i {
    top: 64px;
  }
}

@media (min-width: 345px) and (max-width: 555px) {
  .login .pt-5 {
    margin-top: 50px !important;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .user-show-sec img {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {

  .weekdays li,
  .days li {
    width: 12.5%;
  }

  .days li .active {
    padding: 2px;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 17px;
  }
}

@media (min-width: 458px) and (max-width: 610px) {
  .cc-leftsection_cust label {
    width: 11% !important;
    left: 5%;
  }
}

@media (min-width: 480px) {
  .actionicons-notes {
    margin-top: 8px !important;
  }

  /* Rahul code */
  .main-content {
    padding: 25px 6px;
  }

  .acc-btn-cont {
    padding: 0px 5px;
  }

  /* rahul code */
}

@media (min-width: 481px) and (max-width: 1280px) {
  .drop {
    display: none;
  }

  .closebtn {
    display: none;
  }

  .sidenav {
    width: 200px;
  }

  .main-content {
    width: calc(100% - 202px);
  }

  .vertical-tab-notify .nav-pills {
    width: 17%;
  }

  .usernameinitial {
    height: 30px;
    width: 30px;
    font-size: 15px;
  }
}

@media (min-width: 540px) {
  .tabcontent {
    height: 136vh;
  }
}

@media (min-width: 567.9808px) and (max-width: 922px) {
  .view-button button {
    width: 14%;
  }
}

@media (max-width: 568px) {
  .view-button button {
    width: 19%;
  }
}

@media (max-width: 575px) {
  /* toggle--switch------------- */

  .slide-toggle {
    display: block !important;
  }

  .needhcontainer {
    right: 11px;
  }

  .navbar-nav .dropdown-menu {
    max-height: unset;
  }
  .content-logo.mobileViewIcons .navbar-nav .dropdown-menu {
    max-height: 26.25rem;
  }
  .progess-count-tiel {
    width: 100%;
  }

  .flex-titlereport {
    flex-wrap: wrap;
  }

  .input-text.fliterreportsgen {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 567px) {
  .attention .modal-dialog {
    margin: 8.9rem auto !important;
  }
}

@media (min-width: 575px) and (max-width: 769px) {
  .searchbox {
    border-top: none;
  }
}

@media (max-width: 575px) {
  .cc-lt-rds a img {
    width: 20px;
  }

  .cc-rt-rds a img {
    width: 20px;
  }

  .dt_sun {
    position: relative;
    bottom: 7px !important;
    line-height: 21px;
  }

  .searchbox {
    border-top: none;
  }

  .cc-leftsection label span {
    text-align: left !important;
  }

  .cc-bg-colorr p {
    font-size: 7px !important;
    line-height: 10px !important;
    font-weight: 600;
  }

  .nobannerimage {
    height: 350px;
    /* background: rgb(119 77 159 / 19%); */
  }

  .myOnboarding .border_lines {
    margin-top: 0.5rem;
  }

  .video-full iframe {
    width: 100%;
    height: 20rem;
  }

  .edit_profile_input.view-filter .flex-mbile-col {
    flex-direction: row !important;
  }

  .video-full .video-js {
    width: 100% !important;
    height: 20rem !important;
  }

  .apexcharts-legend.apexcharts-align-center.apx-legend-position-right:before {
    left: -20px;
  }
  .recordingPopup .MuiPaper-root {
    margin: 20px;
  }
  .recordingPopup .MuiDialogContent-root ul li p {
    width: 75% !important;
  }
  .recordingPopup .MuiDialogTitle-root {
    padding: 5px 15px !important;
    font-size: 16px !important;
   }
   .recordingPopup .dialogCloseBtn {
    top: -5px !important;
   }
}

@media (min-width: 575px) and (max-width: 992px) {
  .apexcharts-legend.apexcharts-align-center.apx-legend-position-right:before {
    left: -20px;
  }

  /* .graph-box-learnong {
    margin-bottom: 20px;
  } */

  .video-full iframe {
    width: 100%;
    height: 20rem;
  }

  .nobannerimage {
    height: 350px;
    /* background: rgb(119 77 159 / 19%); */
  }

  .cc-bg-colorr p {
    font-size: 10px !important;
    line-height: 12px !important;
  }

  .navbar-nav .dropdown-menu {
    max-height: unset;
  }

  .myOnboarding .border_lines {
    margin-top: 0.5rem;
  }

  .edit_profile_input.view-filter .flex-mbile-col {
    flex-direction: column !important;
    gap: 1rem !important;
  }

  .video-full .video-js {
    width: 100% !important;
  }

  .button-sec {
    padding-left: 15px;
  }
}

@media (min-width: 580px) and (max-width: 778px) {
  .searchbox {
    border-top: none;
  }
}

@media (max-width: 576px) {
  .chart-two svg {
    width: 100%;
    height: 100%;
    position: inherit;
  }

  .last-section input {
    margin: 10px 0;
  }

  .last-section .submit {
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .tab.mailbox-left-cont {
    height: 120vh;
  }

  .needhcontainer {
    display: none;
  }

  .user_icon label {
    display: none;
  }
}

@media (min-width: 611px) {
  .cc-leftsection_cust label {
    left: 4%;
  }
}

@media screen and (max-width: 720px) {

  .weekdays li,
  .days li {
    width: 13.1%;
  }
}

@media (min-width: 745px) {
  .niitlogo {
    padding: 0rem 0.5rem;
  }
}

@media (max-width: 768px) {
  .detail-page-container {
    width: calc(100%);
  }

  .home-footer {
    position: relative;
    top: 5%;
  }
}

@media (min-width: 573px) and (max-width: 765px) {
  .attention .modal-dialog {
    margin: 6rem auto !important;
  }
}

@media (min-width: 768px) and (max-width: 1199.9808px) {
  .col-4 {
    width: auto !important;
  }

  .events_image>p:first-child {
    color: #000;
    font-family: Verdana;
    font-size: 1rem;
    font-weight: 700;
    line-height: 19px;
    /* height: 2rem; */
  }

  .my_courses .nav-item {
    width: auto;
  }

  .css-1ex1afd-MuiTableCell-root {
    /* padding:8px !important; */
  }

  .header_img_left_ED h6 {
    width: 45% !important;
  }
}

@media (max-width: 768px) {
  .backrightimg {
    display: none;
  }

  .stepsection {
    margin-bottom: 30px;
  }

  .niitlogo {
    height: 28px;
    padding: 0rem 0.5rem;
  }

  .projectlogo {
    font-size: 23px;
  }

  .searchbox {
    width: 100%;
    border-top: 1px solid #fff;
    padding: 8px;
  }

  .navbar-brand {
    margin-bottom: 8px;
  }

  .menu-content {
    font-size: 9px;
  }

  #carouselExampleIndicators .carousel-inner .carousel-item img {
    height: 190px;
  }

  .mob-live-d {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .menu-h-link.nav-link {
    padding: 0.5rem 0.75rem !important;
  }

  .privacy-rghypos {
    position: relative;
  }
}

@media (max-width: 768px) {
  .inputsearch {
    min-width: 0px;
    border-radius: 10px 0px 0px 10px;
  }

  .course-head-al {
    font-size: 15px;
  }

  .col-md-5.actionicons {
    margin-top: -9px;
    margin-left: 12px;
  }

  /* .profile-left-sec {

    padding: 0 13px;
  } */

  .profile_content p {
    width: 100%;
  }

  .act_link {
    margin-bottom: 0;
  }

  .my-5 {
    display: none;
  }

  .resend_link {
    padding-bottom: 0;
  }

  .subscribe_img {
    margin-bottom: 50px;
  }

  .actionicons i:last-child {
    margin-right: 5px !important;
  }

  .nav-link {
    padding: 0.5rem 0.5rem !important;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.1rem 0.25rem 0rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
  }

  .order-select {
    padding: 0.1rem 3rem 0.1rem 0.5rem;
  }

  .price-list {
    margin: 15px 20px;
  }

  .actionicons i {
    width: 20px !important;
    height: 22px !important;
    padding: 6px !important;
    font-size: 10px !important;
    margin-top: 20px !important;
  }

  .course-left-sec {
    background-color: #fff;
    min-height: 55vh;
    padding: 50px 15px;
  }

  .notification-contan span {
    display: none;
  }

  .usernameinitial {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }

  .vertical-tab-notify .nav-pills {
    width: 15%;
  }

  .actionicons-notes {
    position: absolute;
    bottom: 15px;
  }

  .userinfo-bg {
    clear: both;
  }

  .userblock {
    position: absolute;
    z-index: 999;
    background-color: #ff6464;
    width: 100%;
    padding: 10px;
    left: 0;
  }

  .dropselectinp_cn {
    margin-top: 20px;
  }

  .drop {
    display: none;
  }

  .closebtn {
    display: none;
  }

  .footer_contone,
  .footer_conttwo,
  .footer_contthree {
    text-align: center;
  }

  .footer_contone {
    margin-bottom: 0;
  }

  .footer_conttwo {
    padding-top: 0;
    margin-bottom: 0;
  }

  .footer_contthree {
    padding-top: 0;
  }

  /* .footerLogo {
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 20px;

  } */

  .footer_cont {
    padding-top: 0px;
  }

  .footer_cont p {
    line-height: 24px !important;
    text-align: left;
    margin-bottom: 0px;
  }

  /* Roleplaytable */
  .tabroleplay, .review-overview-card .overview-row, .feedback-rating-row {
    font-size: .8rem;
  }

  .lefttext {
    white-space: nowrap;
    font-size: 12px;
  }

  .reviewbox-content {
    padding: 4px 15px;
  }

  .filtervres {
    overflow-x: auto;
  }

  .events_banner.roplbnpag {
    display: block;
  }

  .roplbnpag .rphnain {
    margin-bottom: 20px !important;
  }

  .office_list.roleplaytable .office-province:nth-child(1) {
    width: 200px;
  }

  .office_list.roleplaytable .office-province:nth-child(2) {
    text-align: center;
  }

  .reviews-data-table.table-report-layout .office_list.roleplaytable .office-province:nth-child(4) {
    width: 200px;
    text-align: left;
  }

  .report-table-rsp .office_list.roleplaytable th.office-province:nth-child(5) {
    width: 200px;
  }

  .report-table-rsp .office_list.roleplaytable th.office-province:nth-child(6) {
    width: 200px;
    text-align: left;
  }

  .mobresscroltable {
    overflow-x: auto;
  }

  /* Roleplaytable */
}

@media (min-width: 767px) {
  .nav-link {
    padding: 0.5rem 0.5rem !important;
  }

  .attention .modal-dialog {
    margin: 9.5rem auto !important;
  }
}

@media (min-width: 768px) {
  .inputsearch1 {
    width: 400px;
    border-radius: 10px 0px 0px 10px;
  }
}

@media (max-width: 768px) {
  .course-cards img.img-fluid {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
  }

  .right-sec-detailsshow {
    padding-top: 15px !important;
  }

  .bg-menu-light.navbar {
    margin-top: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1020px) {
  .user-details-view .d-flex.pt-4 {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .dashboard-calendar .container-chart .box .chart-2 {
    margin-left: 0px !important;
    margin-top: 20px;
  }

  .points-icon {
    position: absolute;
    top: 105px !important;
    /* right: 17%; */
  }

  .card_image img {
    height: 12rem;
  }

  .header_img_left h6 {
    width: 318px;
  }

  .shop_Icon_txt {
    border-right: 1px solid #d2d0ce;
    padding: 0 10px !important;
  }

  .module-list {
    padding-left: 0px !important;
  }

}

@media (min-width: 1025px) and (max-width: 1356px) {

  #courseCardCarousel .carousel-indicators,
  #cartCardCarousel .carousel-indicators {
    bottom: 47%;
  }
  .related-video-section-launchpage #courseCardCarousel .carousel-indicators{
    bottom: 51%;
  }
  .course-listing-page #courseCardCarousel .carousel-indicators,
  .course-listing-page #cartCardCarousel .carousel-indicators,
  .my-credit-points.searchpage #courseCardCarousel .carousel-indicators {
    bottom: 51%;
  }

  .cart-wishox #courseCardCarousel .carousel-indicators {
    bottom: 42.1%;
  }

  .cart-body h2 {
    font-size: 18px;
  }

  .btn-primary-LR {
    padding: 0.313rem 1.38rem !important;
  }

  /* .carouseL_IconnBY .sub_txt_lern {
    font-size: 0.675rem;
} */
  .video-details-role .videotimebox span {
    font: normal normal normal 10px/12px Verdana;
  }

  .video-details-role .rolerate strong {
    font: normal normal bold 13px/16px Verdana;
  }

  .video-title-role {
    font: normal normal bold 14px/16px Verdana;
  }

  .review-overview-card .overview-row {
    font: normal normal normal 0.9rem/1.5rem Verdana;
  }

  .points-four {
    bottom: 112px;
    width: 150px;
  }

  .points-four .dot {
    bottom: 13px;
    left: 75px;
  }

  .points-four .tootip-text {
    left: 2px;
    bottom: 41px;
  }

  .learningc-countshow,
  .progess-text-repfot,
  .learning-points h5 {
    font-size: 11px;
  }

  .prgcounttext.tiem {
    font: normal normal bold 1.4rem/3rem Verdana;
  }

  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    text-align: center;
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
  }

  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    min-width: 22px !important;
    width: 22px !important;
  }

  .livebutton {
    padding: 0 0.3rem;
  }

  .my-credit-points.searchpage .btn-primary-startNow {
    padding: 0.375rem 1.8rem;
    font-size: 0.65rem !important;
  }

  .my-credit-points.searchpage .sub_txt_lern {
    font-size: 0.775rem;
  }
  .feedback-rating-card .MuiRating-decimal label {
    font-size: 1.2rem;
}
.fbrolmt {
  margin-top: 1.5rem;
}
.sentimant-box .ratings-role strong {
  font-size: 1rem;
}
.customHoverdrop.dropdown-item {
  white-space: unset;
}
}

@media (min-width: 768px) {
  .recommendationsec {
    width: calc(100%);
  }
}

@media (max-width: 768px) {
  .backrightimg {
    display: none;
  }

  .stepsection {
    margin-bottom: 30px;
  }

  .niitlogo {
    height: 28px;
    padding: 0rem 0.5rem;
  }

  .projectlogo {
    font-size: 23px;
  }

  .searchbox {
    width: 100%;
    border-top: 1px solid #fff;
    padding: 8px;
  }

  .navbar-brand {
    margin-bottom: 8px;
  }

  .menu-content {
    font-size: 9px;
  }

  #carouselExampleIndicators .carousel-inner .carousel-item img {
    height: 250px;
  }

  .mob-live-d {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .menu-h-link {
    padding: 0.5rem 0.75rem !important;
  }

  .menu-h-link.nav-link {
    padding: 0.5rem 0.75rem !important;
  }

  .profilemg {
    width: 100%;
  }

  .realtorSucess {
    width: 100%;
  }

  .profile_inputsearch {
    width: 100% !important;
  }

  .points-icon {
    right: 4%;
  }

  .image-card img {
    width: 100%;
  }

  .img-searcH {
    width: 100%;
  }

  .artCle_container {
    background-color: #fff;
    min-height: 100vh;
    padding: 0 12px 0 12px;
  }

  .artCle_container {
    background-color: #fff;
    min-height: 100vh;
    /* padding: 0 !important; */
  }

  .search_heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .lable-heading_search {
    font-size: 1.9rem;
    text-align: center;
    line-height: 2.625rem;
  }

  .sub_hedgf {
    font-size: 1.55rem;
  }

  .sub_hedg {
    text-align: center;
    padding-top: 20px;
    padding-left: 0;
  }

  .lable-heading_search {
    padding-left: 0px;
  }

  .sub_hedg {
    left: 0;
  }

  .search_heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0px !important;
  }

  /* .search_sec {

    margin: 0 0 !important;

  } */
  /* .container-certificate-bg .col-md-10.offset-1 {margin-left: 0;} */
  .membership_container {
    padding: 0;
  }

  .img-box_nw {
    width: 100%;
  }

  .img-videosec {
    width: 100%;
  }

  .videolistbox .img-videosec {
    height: 10rem;
  }

  .assetlistvideo .img-videosec,
  .videolistbox .img-videosec {
    height: 9.125rem;
  }

  .videofull-view .video-full .video-js {
    height: 20rem !important;
    width: 100% !important;
  }

  .search__input {
    /* width: 31.93%; */
  }

  .revisit_icon {
    position: relative;
    padding-left: 10px;
    /* left: 96px; */
  }

  .input-lear {
    justify-content: space-between;
  }

  .input-lear .button-reset {
    padding-right: 0;
  }

  .carousel.slide {
    height: 100%;
  }

  .bannermodule .slick-slider {
    margin-bottom: 30px;
  }

  .user-details-view .d-flex.pt-4 {
    justify-content: space-between;
  }

  .allcon-wallpage,
  .comment-thread {
    padding: 0;
    width: 100%;
  }

  .commenttorepbox {
    width: 100%;
  }

  .comment-body {
    padding-right: 0;
  }

  .commenttorepbox {
    margin-right: 10px;
  }

  .comment-body .reply-button {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .comment-body .reply-button {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .allcon-wallpage,
  .comment-thread {
    width: 100%;
  }

  .comment-body {
    padding-right: 0;
  }

  .commenttorepbox {
    margin-right: 10px;
  }

  #scorm_player {
    width: 100% !important;
  }

  .invoice-modal .modal-dialog {
    max-width: 690px;
  }

  .dropselectinp_cn {
    margin-top: 20px;
  }

  .profile_projectlogo {
    display: contents;
  }

  .sub_txt_lern {
    font-size: 0.775rem;
    font-family: Verdana;
    font-weight: 700;
    color: #000;
    margin-left: 4px;
  }

  button#openbtn {
    width: 31px;
    height: 32px;
    background: #fff;
    color: var(--color-element-1);
    border-radius: 50%;
    font-size: 1rem;
    border: solid 3px transparent;
    border-radius: 50px;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-image-source: linear-gradient(90deg,
        var(--color-element-1),
        var(--color-element-2));
    border-width: 2px;
    border-image-slice: 1;
    padding: 3px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .course-right-sec {
    flex: 0 0 auto;
    width: 75.33333333%;
  }

  #deleteIcons0 .button-reset {
    padding-right: 0;
  }

  .course-right-sec {
    margin-left: 8%;
  }

  .course-left-sec {
    padding: 50px 0px;
  }

  .box-width {
    width: 30%;
  }

  .John_section.New-dashboard.gap-top-bottom img {
    max-width: 100%;
  }

  /* .broker-box,
  .video_box {
    width: 50%;
  } */
  .input-lear .button-reset {
    padding-left: 10px;
  }

  .course-left-sec .form-check,
  .left-radio-heading {
    margin-left: 15px;
  }

  .action_icons {
    margin-top: -19px !important;
  }

  .course-head-al {
    font-size: 13px;
  }

  .addTocart_btn button {
    top: 12px;
  }

  .product_width {
    width: 100%;
  }

  .product_tb_bg {
    margin-top: 100px !important;
  }

  .health-safety-img img {
    width: 100%;
  }

  .card-width_cust {
    width: 50% !important;
  }

  ul.eventlist li {
    padding: 10px !important;
  }

  ul.eventlist li i {
    right: 4px !important;
    top: 12px !important;
  }

  .act_link {
    width: 50%;
  }

  .join {
    left: 7%;
  }

  .cc-bg-colorr .join {
    width: 27px;
    font-size: 7px;
  }

  #mySidenav.sidenav {
    background-color: #fff;
  }

  .lable-heading {
    padding-left: 10px;
  }

  .welcome-eblast-video {
    width: 80% !important;
  }

  .larng_point {
    margin-left: 10px;
  }

  button.btnDetails {
    width: 30%;
  }

  img.image {
    width: 70%;
  }

  .circularProgress {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .login-text {
    /* padding: 43px 20px 164px; */
    padding: 43px 20px 0px;
  }

  .ttxt p {
    margin-left: 0 !important;
  }

  .login h3 {
    margin-top: 0.725rem;
    margin-bottom: 1.25rem;
  }

  .mb-2.login-msg.h-16 {
    height: auto;
  }

  .login .form-row {
    margin-top: 2.125rem;
  }

  .ttxt img {
    margin-bottom: 1.6125rem;
    margin-top: 0;
  }

  .image-login img {
    height: 100vh;
  }

  .certify-table {
    overflow-x: auto;
  }

  .privacy-rghypos {
    position: relative;
    right: 0;
    top: 0;
  }

  .wrapper-desc.cursor-pointer {
    height: auto;
    flex-direction: column;
  }

  .wrapper-desc.cursor-pointer p {
    width: 48%;
  }

  .cart-wrapper .wrapper-desc.cursor-pointer p {
    width: 100%;
  }

  .cart-wrapper .desc-wrap.content-cart {
    justify-content: space-between;
    margin-top: 20px;
  }

  .slider-card {
    position: absolute;
    top: 1%;
    right: 0rem;
    left: 0;
    margin-bottom: 20px;
  }

  .slider-card .row {
    justify-content: center;
  }

  .dashboard-calendar .box h3 {
    text-align: left;
  }

  /* .container-chart:not(:last-child) {
    padding-right: 15px;
  } */
  .weektable-acc .table.table-bordered {
    table-layout: fixed;
  }

  .weektable-acc .table-bordered thead tr th {
    width: 90px;
  }

  .progress__txtBarRS {
    padding-left: 15px;
  }

  .progress__txtBar {
    padding-left: 15px;
  }

  .rbc-calendar .rbc-btn-group:last-child {
    width: 100%;
    margin-top: 10px;
  }

  .membershipplanmob .row.no-wrap {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .membershipplanmob .row.no-wrap .price-list {
    width: 40%;
  }

  .certificate-section {
    /* height: 16rem; */
    width: 100%;
  }

  /* .flexboxprof {
    width: 100%;
    margin: 0 -15px;
    padding: 0;
  } */
  /* .certificate-sec {
    width: 50%;
    padding: 0 15px;
    margin-right: 0;
  } */
  /* .badges-sections {
    flex-wrap: wrap;
  }
  .badges-section {
    width: 288px;
    height: 288px;
  }

  .badge-sec {
    width: 50%;
    margin-right: 0;
    padding: 0 15px;
  }

  .badges-sections {
    flex-wrap: wrap;
    margin: 0 -15px;
    padding: 0;
  } */
  .setup-button {
    padding: 0.375rem 1.063rem !important;
  }

  .button-sec .setup-button {
    padding: 0.375rem 0.663rem !important;
  }

  .accpet-box-btn-list {
    width: 65%;
  }

  .input-text.position-relative.d-flex.res-btn-rsp {
    width: calc(100% - 65%);
  }

  .table-responsive1.rsp-team-lead-table {
    margin-bottom: 2.25rem;
  }

  .realtor__safety {
    height: 19rem !important;
  }

  .Payment-container .row {
    width: 100%;
  }

  .payment.payment-form-section .image-login {
    position: relative;
  }

  .payment.payment-form-section .card.border-light.payment-card {
    max-width: 100% !important;
    padding: 10px;
  }

  .payment.payment-form-section .card-body.payment-body .body-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .payment.payment-form-section .body-card h2 {
    font-size: 24px;
  }

  .payment.payment-form-section .submit_btn.payment_btn {
    padding-left: 0 !important;
  }

  .payment.payment-form-section .form-select {
    padding: 0.1rem 1.6rem 0rem 0.5rem;
  }

  .payment.payment-form-section .input_searchfield {
    font-weight: 400;
  }

  .my-credit-points.searchpage .input_field {
    width: 100%;
  }

  /* .my-credit-points.video-page-rsp .search__input {
    width: 50%;
  } */
  .cl-video.video-modal.modal-dialog {
    max-width: 675px;
  }

  /* .certify-detail-logo img {
    width: 2rem;
    height: 1rem;
  } */
  /* .certify-wrap {
    align-items: flex-end;
  } */
  body .content-logo.mobileViewIcons .notification-nav .dropdown-menu {
    left: -282px !important;
  }

  .certificat-container.checklist-section .slick-track {
    min-width: 100%;
  }

  .pointshead {
    padding: 0px 20px;
  }

  .points-box {
    padding: 17px 20px;
  }

  .points-table.padlist {
    padding-left: 20px;
    padding-right: 20px;
  }

  .table-poits-row {
    width: 100%;
  }

  .table-poits-row .col-4.rightpoins {
    width: 33.33333333% !important;
  }

  .accept-all {
    text-align: right;
  }

  .bannermodule .slick-dots {
    bottom: 0px !important;
    position: relative !important;
  }

  #myTabContent .note-thumb {
    margin-left: 0;
    padding-left: 0;
  }

  #myTabContent .notes-box {
    padding-left: 0;
  }

  .note-thumb .img-thumbnail.cursor-pointer {
    padding-left: 0;
  }

  #deleteIcons0 {
    margin-left: 0px;
  }

  .note-thumb .bg.cursor-pointer {
    margin-bottom: 5px;
  }

  #my-favourites .fav-title-index.thumb-image {
    padding-left: 0;
  }

  #my-favourites .fav-delete {
    padding-right: 0;
  }

  #my-favourites .fav-delete .bg {
    padding-right: 0;
  }

  #notes .row .col-md-12 {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  #notes .notes-text .thumb-image>p {
    margin-left: 0;
    padding-left: 0;
  }

  #notes .notes-desc {
    margin-left: 0;
    margin-top: 5px;
    padding: 5px;
  }

  .edit_btn button {
    padding: 3px 15px;
  }

  /* .table-report-layout .col-7 {
  flex: 0 0 auto;
  width: 33.33333333% !important;
} */
  .table-report-layout .tablebox {
    overflow-x: auto;
  }

  .table-report-layout .table.office_list {
    table-layout: fixed;
  }

  .table-report-layout .office-province {
    width: 225px;
  }

  .table-report-layout .table> :not(caption)>*>* {
    word-break: break-word;
    vertical-align: top;
  }

  .calscroll .rbc-day-slot .rbc-event,
  .calscroll .rbc-day-slot .rbc-background-event {
    min-height: 100px;
  }

  .popover-price {
    font-size: 0.6rem;
  }

  .invoice-modal .modal-content .modal-body {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .card.border-light.payment-card {
    max-width: 32rem !important;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .table-report-layout .button-reset.downbtnsreport {
    margin-top: 20px;
  }

  .recording-sec {
    flex-wrap: wrap;
  }

  .video-details-role {
    width: 100%;
  }

  .modal-90w {
    width: 72%;
  }

  .mob-res.w-100 {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .details-cards-oll h5 {
    font-size: 16px;
  }

  .carousel.slide {
    height: auto !important;
  }

  .backrightimg {
    display: none;
  }

  .carousel_slide {
    width: 100%;
  }

  .card-width_cust {
    width: 100%;
  }

  .search__input {
    /* width: 31.93%; */
  }

  .stick-top {
    top: 76px;
  }
}

@media (min-width: 770px) {
  .drop {
    display: none !important;
  }

  .closebtn {
    display: none !important;
  }
}

@media (min-width: 810px) {
  .nav-link {
    padding: 0.5rem 0.5rem !important;
  }

  .revisit-btn,
  .continue-btn,
  .locked-btn {
    position: relative;
    bottom: -26px !important;
    left: 24px;
  }

  .home-footer {
    position: relative;
    top: 85px !important;
  }

  .lession.mb-4 {
    width: 100% !important;
  }

  .niitlogo {
    padding: 0rem 0.5rem;
  }

  .cc-header {
    font-size: 14px !important;
  }

  /* 
  .pt-4 {
    font-size: 14px !important;
  } */

  .searchbox {
    border-top: none;
  }
}

@media (min-width: 810px) {
  .refinefilter .form-select {
    min-width: 100px;
  }

  .inputsearch {
    min-width: 100px;
    border-radius: 10px 0px 0px 10px;
  }

  .drop {
    display: none;
  }

  .closebtn {
    display: none;
  }

  .nav-link {
    /* padding: 0.4rem 0.6rem !important; */
  }

  .bg-menu-light.navbar {
    padding-left: 16px;
  }

  .vertical-tab-notify .nav-pills {
    width: 18%;
  }

  .price-list {
    margin: 20px;
  }

  .userblock {
    display: block !important;
  }
}



@media (min-width: 992px) {
  #carouselExampleIndicators .carousel-inner .carousel-item img {
    height: 350px;
  }

  .chart-two {
    margin-bottom: 17px !important;
  }

  .price-list {
    margin: 20px;
  }

  .content_profile_pg {
    display: block;
    height: 100%;
  }
}

@media (min-width: 1020px) and (max-width: 1380px) {
  .finishit-btn {
    margin: -6px 0px 0px 21%;
  }

  .d-flex span {
    font-size: 0.875rem;
  }

  .notifydroprsp .noticount {
    font-size: 10px;
  }

  .event-title .popover-price {
    font-size: 0.6rem;
  }

  .module-list {
    padding-left: 0px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {

  .privacy-rghypos {
    position: relative;
  }

  .infoIcon_nw .sub_txt_lern {
    font-size: 0.675rem;
  }

  .dropselectinp {
    padding: 0 7px;
    width: 100%;
  }

  .info-bottm-card_nw p,
  .videolistbox .videoviews .videocount {
    font-size: 0.65rem !important;
  }

  .events_image>p:first-child {
    height: 2rem;
  }

  .more_events .eventtitle {
    height: 2.4rem !important;

  }

  .card_image img, .event-without-img {
    height: 14rem;
  }

  .wrapper-desc {
    height: 46px;
  }

  /*.dropselectinp {padding: 0; width:100%;}*/
  .d-flex.justify-content-between.filtersection.mb-3 {
    display: block !important;
  }

  .refinefilter {
    display: flex;
    margin-top: 1em;
  }

  .refinefilter div.row:last-child {
    margin-left: 0;
    width: 100%;
  }

  .membershipplanmob .row.no-wrap {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .membershipplanmob .row.no-wrap .price-list {
    width: 40%;
  }

  .profile-right-sec .edit_profile_input {
    width: 100%;
  }

  .accordion-item .weektable-acc .btn-join {
    font-size: 0.6rem;
  }

  .report-table-rsp .office_list td,
  .report-table-rsp .office_list th,
  .form-group.input-text.checkbox-field>span,
  .view-selectfilter.coursefilter label {
    font-size: 0.8rem;
  }

  .feedback-rating-row {
    padding: 0.85rem 1rem;
  }
}

@media (min-width: 1024px) and (max-width: 1190px) {
  .infosection {
    margin: 0;
  }
}

@media (min-width: 1025px) {
  .nobannerimage {
    height: 350px;
    /* background: rgb(119 77 159 / 19%); */
  }
}

@media (max-width: 1024px) {
  .dropselectinp_cn {
    padding: 0 7px;
    width: 100%;
    margin-top: 20px;
  }

  .modules-menu-item .left-arrow.button-reset>.button-reset {
    padding-left: 7px !important;
  }

  .profile-right-sec .edit_profile_input {
    width: 100%;
  }

  .body-card h2 {
    font-size: 1.4rem;
  }

  .cart-total h2 {
    font-size: 1.3rem;
  }

  .noticount {
    top: 0px;
  }

  .video-details-role .videotimebox span {
    font: normal normal normal 10px/12px Verdana;
  }

  .video-details-role .rolerate strong {
    font: normal normal bold 13px/16px Verdana;
  }

  .video-title-role {
    font: normal normal bold 14px/16px Verdana;
  }

  .report-graph-trend {
    flex-direction: column;
  }

  .chartfilterbox {
    border-left: none;
  }

  .report-graph-trend .chartfilterbox {
    width: 100%;
    padding-top: 0;
  }
  .privacy-termspage .btn-procced-agree.btn-primary {
    margin: 0.5rem 0.625rem;
    padding: 0.2rem 2rem;
}
.privacy-termspage .btn-procced-agree.btn-primary:disabled{
    border-width:2px;
}
}

@media (min-width: 1096px) {
  .cc-leftsection_cust label {
    left: 0;
    font-size: 16px !important;
    left: 4%;

    width: 11% !important;
  }
}

/* ---Start--Ronnie Woodkin--Progress bar */
@media (min-width: 1099.9808px) and (max-width: 1199.9808px) {
  .container-chart .box .chart-2 i {
    font-size: 15px;
  }

  .container-chart .box .chart-2 {
    position: relative;
    width: 106px;
    height: 80%;
    text-align: center;
    font-size: 13px;
    line-height: 15px;
    color: #1a3a4c;
    padding: 24px 15px;
    font-family: "museo700";
  }
}

/* jitendra code */

@media (min-width: 1200px) and (max-width: 1299.9808px) {
  .container-chart .box .chart-2 i {
    font-size: 15px;
  }

  .container-chart .box .chart-2 {
    position: relative;
    width: 106px;
    height: 80%;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    color: #1a3a4c;
    /* padding: 27px 15px; */
    font-family: "museo700";
  }

  .learng_finsh span button {
    margin-left: 10px !important;
  }
}

@media (max-width: 1200px) {
  .side-navbar .nav-side-title {
    font: normal normal bold 1rem/1.2rem Verdana;
    margin-bottom: 1.5rem;
  }
  .side-navbar .side-nav-custom .nav-link {
  font: normal normal bold 1rem/1.1rem Verdana;
  }
  .col-md-6.main_realtor {
    margin-bottom: 15px;
}

.d-flex.mt24.mbtop {
    margin-top: 0;
    padding: 8px 0;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    display: flex !important
}
.d-flex.mt24.mbtop .btn.searchBtn {
    padding: 0rem 0.5rem !important;
}
.step1 {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
}
.content-logo.mobileViewIcons> div {
  width: 33.33%;
}

.mobcenterlogo {
  text-align: center;
}
.profile-nav.nav-item.dropdown .nav-link.dropdown-toggle {
  padding-right: 0rem !important;
  padding-left: 0 !important;
}
.cursor-pointer.cart-item-report {
  margin-top: 0 !important;
  position: relative;
}

  .profile_inputsearch {
    width: 100% !important;
  }

  .content-logo.mobileViewIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 0.75rem 0.5rem;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 22%);
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9999;
    height: 95px;
    border-top: 1px solid #ddd;
}

  .profile-nav .dropdown-menu {
    inset: unset !important;
    transform: unset !important;
   right: -100% !important;
    width: 250px;
    top: 130px !important;
    display: block;
    position: fixed !important;
    transition: .5s linear right;
  }
  .profile_thumbImg.moDeviceAvtaar.scrolledmob .profile-nav .dropdown-menu{
    top: 90px !important;
}
  .profile-nav .dropdown-menu.show {
    right: 0% !important;
    transition: .5s linear right;
    
}
  .livebuttonDiable img {
    width: 8px;
  }

  .navbar_custom a {
    border-bottom: unset;
    font-size: 14px;
    color: #000;
    font-weight: bold;
  }

  .nav-link {
    padding: 0rem 0.5rem !important;
  }

  .livebuttonDiable {
    background-image: linear-gradient(270deg, #8f9394, #9c9497);
    border-radius: 0.125rem;
    color: #fff;
    padding: 0px 11px 2px 10px;
    border: none;
    margin-top: 0;
  }

  .livebuttonDisableSecd {
    background-image: linear-gradient(270deg, #8f9394, #9c9497);
    border-radius: 0.125rem;
    color: #fff;
    padding: 0px 11px 2px 10px;
    border: none;
    margin-top: 0px;
    position: relative;
    bottom: 4px;
  }

  .dropdown .dropdown-menu .nav-item {
    border-bottom: 0.063rem solid #dbd8d6;
    background: #f7f7f7;
  }

  .noticount {
    top: -4px;
    right: 22px;
  }
  .d-flex.mt24.mbtop svg {
    width: 22px;
    height: 22px;
}
  .content-logo.mobileViewIcons .navbar-brand {
    display: block;
    margin: 0;
    padding: 0 0 0 0px;
  }
  .content-logo.mobileViewIcons .mobnotify svg {
    width: 23px;
    height: 23px;
}
  .niit_moblogo {
    width: 70px;
  }

  /* img.niit_moblogo {
    width: 70px;
  } */

  .moDeviceAvtaar a.dropdown-toggle.nav-link>div>div {
    width: 30px !important;
    height: 30px !important;
    margin: auto;
  }

  .wlcmtxt .nameusershow {
    display: block;
  }

  .wlcmtxt {
    text-align: center;
    font-size: 10px;
    line-height: 14px;
    display:none;
    padding-top: 10px;
  }

  .moDeviceAvtaar a.dropdown-toggle.nav-link>div>.premium-diamond {
    width: 1.2rem !important;
    height: 1.2rem !important;
    top: -0.5rem;
    right: -5px;
  }

  .moDeviceAvtaar a.dropdown-toggle.nav-link>div>.premium-diamond span {
    display: block;
  }

  .stick-top {
    top: 5.3rem;
  }

  .mt-5cal .react-calendar {
    margin: 15px 0;
  }

  .calendar-content .month {
    padding-top: 0rem;
    display: flex;
    justify-content: center;
  }

  .profile-nav.nav-item.dropdown div>div img {
    width: 30px !important;
    height: 30px !important;
  }

  .mobilenav {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 0.8rem;
  }
  .notification-nav.nav-item.dropdown .nav-link.dropdown-toggle{
    padding-right: 0.8rem !important;
  }
  .content-logo.mobileViewIcons .d-flex.mt24 {
    margin-top: 0;
    align-items: center;
    justify-content: flex-end;
  }

  .content-logo.mobileViewIcons .livestrembtn {
    margin-top: 0;
  }

  .profile-banner h1 {
    font-size: 2.125rem;
    padding: 0;
    margin-bottom: 30px;
  }

  .nav-item.show .dropdown-toggle.nav-link {
    border-bottom: none;
    padding: 5px 0;
  }

  .content-logo.mobileViewIcons .notification-nav .dropdown-item {
    display: flex;
    flex-wrap: wrap;
    white-space: unset;
    padding: 10px;
  }

  .content-logo.mobileViewIcons .mobnotify {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }

  .reviewbox-content {
    flex-direction: column;
  }

  .content-logo.mobileViewIcons .mobnotify .getCertified_content {
    width: 100%;
  }

  .content-logo.mobileViewIcons .mobnotify .getCertified.buutndf button {
    width: 150px;
    font-size: 0.675rem;
  }

  .content-logo.mobileViewIcons .mobnotify .notification-nav h6,
  .getCertified_content p.text-right {
    font-size: 0.7rem;
  }

  .content-logo.mobileViewIcons .notification-nav .dropdown-menu {
    width: 350px;
    inset: unset !important;
    left: -275px !important;
    transform: unset !important;
  }

  .getCertified_content {
    width: 80%;
  }

  .dropdown-menu.menu-cont.certy_cont.M0Hamburger .nav-item {
    padding: 7px;
  }

  .input_searchfield_search {
    width: 100%;
  }

  .dropdown-menu.menu-cont.certy_cont.M0Hamburger .dropdown-toggle::after {
    position: absolute;
    right: 10px;
  }

  .dropdown-menu.menu-cont.certy_cont.M0Hamburger {
    width: 18rem;
  }

  .dropdown-menu.menu-cont.certy_cont.M0Hamburger .dropdown-menu {
    position: relative !important;
    top: 0 !important;
    transform: unset !important;
  }
  #mySidenav.sidenav{
    left: -100%;
    width: 400px;
    position: fixed;
    padding-top: 0;
    margin-top: 0 !important;
    z-index: 9;
    top: 130px;
    background-color: #ffffff;
  }
  .main.scrollnavmob #mySidenav.sidenav{
    top:90px;
  }
  #mySidenav.sidenav.open {
    left: 0;
  }
  .main, .home-footer_new{
    margin-left: 0 !important;
  }
  #mySidenav.sidenav.open .notes-text {
    margin: 0;
  }

  .icon_LP {
    padding-left: 10px;
  }

  .dropdown-menu.menu-cont.certy_cont.M0Hamburger {
    top: 0;
    position: absolute;
    display: block !important;
    left: 0;
  }

  .video_img {
    display: flex;
    align-items: center;
  }

  .module-list .module-item-container a {
    padding: 0;
  }

  .orders .d-flex.order-column {
    flex-direction: column;
    padding: 0;
  }

  .orders .search__input {
    width: 100%;
  }

  /* .my_order .cart-description-ex-border {
  flex-direction: column;
} */

  /* .my_order .desc-content {
  width: 100%; 
  margin-top: 15px;
   padding-left: 0;
} */

  .my_order .bg-content {
    padding: 13px;
  }

  .my_order button.btn-order {
    padding: 6px 20px;
  }

  .my_order .order-content {
    margin-top: 24px;
    align-items: flex-start;
  }

  .my_order .order-text {
    padding-right: 10px;
  }

  .my_order .row.orders {
    margin: 2rem 0 0;
  }

  .orders .select-date {
    width: 100%;
    justify-content: flex-end;
    grid-gap: 15px;
    gap: 15px;
  }

  .orders .input_searchfield.cart-select {
    padding-right: 40px;
  }

  .alert-card .shop-cart {
    height: auto;
    width: 100%;
    padding: 10px 40px 10px 10px;
    align-items: flex-start;
    text-align: left;
    position: relative;
  }

  .alert-card {
    position: relative;
  }
  .alert-card.homealertbox {
    position: fixed;
    top: 10%;
    width: 92%;
    margin: 10px auto !important;
    left: 0;
    right: 0;
}
  .alert-card .view-cart-icon {
    display: block;
    width: 25%;
  }

  .alert-card .view-cart-icon i {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .alert-card .shop-info {
    width: 75%;
    padding-right: 10px;
    justify-content: flex-start !important;
  }
  .alert-card.homealertbox, .alert-card.premimimmember {
    position: fixed;
    top: 10%;
    width: 92%;
    margin: 10px auto !important;
    left: 0;
    right: 0;
}

.alert-card.premimimmember .premium-band {
    height: auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.alert-card.premimimmember .shop-info {
    width: 100%;
    flex-direction: column;
}

.alert-card.premimimmember .view-cart-icon {
    width: 100%;
    margin-top: 5px;
}
  /* .rbc-event .rbc-event-content .event-block div {
  flex-direction: column;
} */
  .calscroll .rbc-calendar .rbc-time-header,
  .calscroll .rbc-calendar .rbc-time-content,
  .rbc-month-header,
  .rbc-month-row {
    width: 900px !important;
  }

  .calscroll .rbc-time-view,
  .calscroll .rbc-month-view {
    overflow-x: auto;
  }

  .card.border-light.payment-card {
    margin-top: 2rem;
  }

  /* .profile-nav.nav-item.dropdown .nav-link.dropdown-toggle .cursor-pointer.cart-item-report img {
    width: 30px !important;
    height: 30px !important;
  } */

  .profile-nav.nav-item.dropdown .nav-link.dropdown-toggle .cursor-pointer.cart-item-report .cart-text {
    font-size: 10px;
  }

  .bg-menu-lightWhite {
    display: none;
  }
  .livestrembtn.streamlive:hover .dropdown-menu {
    left: -230px;
}
.livestrembtn.streamlive .livedrop.dropdown-menu {
  width: 19rem;
  z-index: 99999;
}
.no-graph-data {
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.open.fixetooglebtn{
  display: none;
}
.no-graph-data .legends-data {
  width: 100%;
    padding: 5px 0;
    margin: 0;
    border: none;
    justify-content: center;
}
.no-graph-data img {
margin: 0 auto;
}
.no-graph-data .legends-data ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.no-graph-data .legends-data ul li {
  list-style: none;
  width: 50%;
 
}
.chartsmboxreport .apexcharts-legend {
  max-height: 100% !important;
}

.chartsmboxreport .apexcharts-canvas {
  margin: auto;
}
.chartsmboxreport .apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series, .chartsmboxreport .apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  display: flex;
  align-items: center;
  width: 45%;
  margin: 5px !important;
}

.fixed-loader p {
  text-align: center;
}


}

@media (min-width: 1200px) and (max-width: 1356px) {
  .navbar-collapse ul li {
    padding-right: unset;
  }
  .livestrembtn.streamlive .livedrop.dropdown-menu {
    overflow: hidden;
    width: 20rem;
}
  .ico_texxt p span,
  .info-bottm-card_nw p,
  .cou_cardIcon p span,
  .videolistbox .videoviews .videocount,
  .videolistbox .videoviews,
  .btn-primary-LR {
    font-size: 0.65rem !important;
  }

  .carouseL_IconnBY .sub_txt_lern {
    font-size: 0.775rem;
  }

  .btn-plan {
    padding: 0.4rem 3.625rem;
  }

  .shopping .carousel-indicators {
    bottom: 51% !important;
  }

  .img-videosec {
    position: relative;
    /* width: 230px; */
    /* height: 147px; */
    overflow: hidden;
    display: inline-block;
    margin: 0 auto;
  }

  .img-videosec {
    height: 12.5rem;
  }

  .pfl-section .img-videosec.learn_image-card.cross_Iconn {
    height: 9.1875rem;
  }

  .accordion-item .weektable-acc .btn-join {
    font-size: 0.6rem;
  }

  .my_cart .cart-timer p,
  .my_cart .cart-timer p:last-child,
  .my_cart .bundle-course-length {
    font-size: 10px !important;
  }

  .report-table-rsp .office_list td,
  .report-table-rsp .office_list th {
    font-size: 0.8rem;
  }

  .leaderboad-graph {
    height: 200px;
  }

  .edit_profile_input {
    width: calc(100% - 60px);
  }

  .badges-section {
    height: 14.25rem;
    width: 14.25rem;
  }

  .wlcmtxt .nameusershow {
    display: block;

  }
  #mySidenav.sidenav.open {
    width: 332px;
}
#mySidenav .nav-tabs .nav-item button {
  font-size: .775rem;
}

}

@media (min-width: 1281px) {
  .nav-link {
    padding: 0.5rem 1.5rem;
  }

  .d-flex.searchbox {
    position: relative;
    right: 22px;
  }
}
@media (min-width: 1356px){
  .related-video-section-launchpage {
    width: 90%;
    margin: 0 auto;
  }

}
@media (min-width: 1281px) {
  .d-flex.searchbox {
    position: relative;
    right: 22px;
  }

  .niitlogo {
    padding: 0rem 1.5rem;
  }
}

/* Ravi -implemented */
@media (min-width: 1900px) {
  .w-1320 {
    max-width: 1320px;
    margin: 0 auto;
  }
}

/* End--Ravi -implemented */

@media (min-width: 1025px) and (max-width: 1600px) {
  .realtor_manager_login .profile-nav .dropdown-menu {
    max-height: 480px;
    overflow-y: auto;
    min-width: 265px;
  }

  .navbar-nav.profile_thumbImg.host_inst_login .dropdown-menu {
    height: auto;
  }
}

@media (min-width: 1025px) and (max-width: 1299px) {
  .realtor_manager_login .profile-nav .dropdown-menu {
    max-height: 360px;
  }

  .navbar-nav.profile_thumbImg.host_inst_login .dropdown-menu {
    height: auto;
  }

  .content-cart h2 {
    font-size: 1.5rem;
  }

  .body-card h2 {
    font-size: 1.75rem;

  }
}

/* @media (min-width:1300px)and (max-width:1349px) {
  .popup-home02 {
    right: 31%;
  }

  .popup-home {
    right: 8%;
  }
}

@media (min-width:1300px)and (max-width:1349px) {
  .popup-home02 {
    right: 31%;
  }

  .popup-home {
    right: 8%;
  }
} */
@media (min-width: 1366px) and (max-width: 1600px) {

  .numr29,
  .shop-cardSUP h5 sup {
    font-size: 1.125rem !important;
  }

  h4.strike-through,
  h4.strike-through span.numr29 {
    font-size: 0.9rem !important;
  }

  .oboardg_pop_icon.shop-cardSUP h5,
  .shop-cost {
    font-size: 18px;
  }

  .shop-offer h5 {
    font-size: 0.9rem;
  }

  .shop-offer p {
    font-size: 0.775rem;
  }
}

@media (min-width:1400px)and (max-width:1550px) {
  .nav-link, .bg-menu-lightWhite .nav-link.dropdown-toggle, .info-bottm-card_nw p, .info-bottm-card_nw span {
    font-size: 0.875rem;
}
.wlcmtxt .nameusershow {
  max-width: 7.375rem;
}
.wlcmtxt {
  font-size: 0.9rem;
}
}

/* @media (min-width:1500px)and (max-width:1549px) {
  .popup-home02 {
    right: 27%;
  }

  .popup-home {
    right: 7.4%;
  }
} */

/* @media (min-width:1870px)and (max-width:1920px) {
  .popup-home02 {
    right: 32%;
  }

  .popup-home {
    right: 16.4%;
  }
  .popup-home02 {
    right: 26.3%;
}
} */

@media (min-width: 1400px) {
  /* .disable_Card {
    height: 364px;

  } */
}

@media (min-width: 340px) and (max-width: 1200px) {
  .moDevice {
    background-color: #fff;
    margin-top: 37px;
  }

  .moDevice .dropdown {
    border-bottom: 1px solid #dbd8d6;
  }

  /* .content-profile button{margin-right:20px;} */
  .navbar_custom a {
    /* border-bottom: 1px solid #dbd8d6; */
    white-space: unset;
  }

  .dropdown-menu.menu-cont.certy_cont.M0Hamburger .dropdown-menu a:last-child {
    border: none !important;
  }

  .moDeviceAvtaar {
    background-color: #fff;
  }

  .nav-link {
    padding: 0rem 0.5rem !important;
  }

  .my_courses .nav-tabs .nav-link {
    padding: 0.3rem 0.5rem !important;
  }

  .buutndf .nav-link.dropdown-toggle {
    padding: 0.5rem !important;
  }

  ul li .MoHamger {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mobileViewIconsDetails svg {
    margin: 0 5px;
    width: 23px;
    height: 23px;
  }

  .newupgrade_btn {
    margin-bottom: 10px;
  }

  .navbar_customBtn {
    border-bottom: 1px solid #dbd8d6;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .container-chart .box .chart-8 {
    position: relative;
    width: 92px;
    height: 100%;
    text-align: center;
    font-size: 1rem;
    line-height: 50px;
    color: #1a3a4c;
    padding: 33px 0px;
    font-family: "Verdana";
  }
}

/* start  Media-query--06-june */

@media (min-width: 319px) and (max-width: 320px) {
  /* .popup-home,
  .popup-home02 {
    display: none;
  } */

  .text-white {
    padding: 0px 11px;
  }

  .calen-section {
    margin-top: 10px !important;
  }

  .New-dashboard {
    margin-top: 10px !important;
  }

  .mainHEADING {
    font-size: 20px;
  }

  .Upcoming-bg-color {
    padding: 0 10px !important;
  }

  #carouselExampleIndicators p span {
    font-size: 1rem !important;
  }

  .My_Dashboard h3 {
    font-size: 1.5rem;
  }

  .carousel-item .text-white .mb-4 {
    margin-bottom: 8px !important;
  }

  section .container .My_dashboard a button svg {
    width: 24px !important;
    height: 24px !important;
  }
}

@media (min-width: 374px) and (max-width: 375px) {
  /* .popup-home,
  .popup-home02 {
    display: none;
  } */

  .text-white {
    padding: 0px 11px;
  }

  .calen-section {
    margin-top: 10px !important;
  }

  .New-dashboard {
    margin-top: 10px !important;
  }

  .mainHEADING {
    font-size: 1.666rem;
    margin-bottom: -6px;
  }

  .Upcoming-bg-color {
    padding: 0 10px !important;
  }

  #carouselExampleIndicators p span {
    font-size: 1rem !important;
  }

  .My_Dashboard h3 {
    font-size: 1.5rem;
  }

  .carousel-item .text-white .mb-4 {
    margin-bottom: 8px !important;
  }

  section .container .My_dashboard a button svg {
    width: 24px !important;
    height: 24px !important;
  }
}

@media (min-width: 424px) and (max-width: 425px) {
  /* .popup-home,
  .popup-home02 {
    display: none;
  } */

  .text-white {
    padding: 0px 11px;
  }

  .calen-section {
    margin-top: 10px !important;
  }

  .New-dashboard {
    margin-top: 10px !important;
  }

  .mainHEADING {
    font-size: 20px;
  }

  .Upcoming-bg-color {
    padding: 0 10px !important;
  }

  #carouselExampleIndicators p span {
    font-size: 1rem !important;
  }

  .My_Dashboard h3 {
    font-size: 1.5rem;
  }

  .carousel-item .text-white .mb-4 {
    margin-bottom: 8px !important;
  }

  section .container .My_dashboard a button svg {
    width: 24px !important;
    height: 24px !important;
  }
}

@media (min-width: 767px) and (max-width: 768px) {
  .text-center {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  /* .popup-home {
    right: 17.4rem;
  }
  .popup-home02 {
    right: 3rem;
  } */
  .popup-home,
  .popup-home02,
  .popup-home03 {
    /* top: 5%; */
    width: 230px !important;
    z-index: 1;
    min-height: 210px !important;
  }

  .footer-title {
    position: relative;
    bottom: 2%;
    margin-top: 32px;
    text-align: right;
  }

  .home-footer_new .col-md-6 {
    width: auto;
  }

  .home-footer_new .row {
    justify-content: center;
  }

  .cart-body {
    flex-direction: column;
  }

  .btn-carts.cart-btn-primary {
    margin-top: 10px;
  }

}

/* end Media-query--06-june */
@media (min-width: 1025px) and (max-width: 1365px) {
  .login-text {
    padding: 43px 40px 20px;
  }

  .ttxt img {
    margin-bottom: 2.125rem;
  }

  .login h3 {
    margin-top: 1.5rem;
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
  }

  .numr29,
  .shop-cardSUP h5 sup {
    font-size: 1.125rem !important;
  }

  h4.strike-through,
  h4.strike-through span.numr29 {
    font-size: 0.8rem !important;
  }

  .oboardg_pop_icon.shop-cardSUP h5,
  .shop-cost {
    font-size: 0.875rem;
  }

  .shop-offer h5 {
    font-size: 0.8rem;
  }

  .shop-offer p {
    font-size: 0.675rem;
  }

}

@media (min-width: 540px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 95%;
  }
}

@media (min-width: 768px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 95%;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 85%;
  }

  .btn-primary-LR {
    padding: 0.313rem 0.688rem !important;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 85%;
  }

}

/* @media (min-width: 1919px) and (max-width: 1920px) {
    .payment{
        height: 67vh !important;
    
    }
  } */

@media (min-width: 360px) and (max-width: 800px) {
  .footerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    gap: 10px;
  }

  .order-total_wrapper .content-cart h2 {
    font-size: 18px;
  }

  .order-cart {
    padding-right: unset;
  }

  .image-cart {
    width: 50%;
  }

  /* .profileICON {
    left:218px;
  } */
  .header_img_left_ED h6 {
    width: 95%;
    /* padding: 7.625rem !important; */
  }

  .module-list {
    padding-left: 0px !important;
  }

  .footer_logoone,
  .footer_logotwo {
    padding: 0px;
    gap: 10px;
  }

  .footer_logoone img {
    height: 15px;
    width: 36px;
  }

  .module-border-box {
    padding-left: 1rem;
    padding-top: 0;
  }

  .footer_logotwo img {
    height: 30px;
    width: 56px;
  }

  .ttxt {
    padding: 0px;
  }

  /* .pop_modal_body {
    padding: 25px;
  } */

  .footer_contone,
  .footer_conttwo,
  .footer_contthree {
    font-size: 0.6rem !important;
  }

  /* .footer-row {
      flex-wrap: inherit;
    } */
  .footer_cont {
    /* padding: 0.25rem 0 0.25rem; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .footer_contone {
    font-weight: bold !important;
  }

  /* .ttxt img {
    margin-bottom: 0rem;
  } */

  .footer_conttwo {
    opacity: 80% !important;
  }

  .footer_data {
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .card.border-light.payment-card {
    max-width: 32rem !important;
    margin-top: 2rem;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .submit_btn.payment_btn {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .shop_Icon_txt {
    border-right: none;
  }

  .btn-plan {
    padding: 0.4rem 2.625rem;
  }

  .shop_Icon_txt {
    border-right: 1px solid #d2d0ce;
    padding: 0px 0.35rem !important;
    width: 25%;
  }

  .card_image img {
    height: 12rem;
  }

  .shop_Icon_txt:last-child {
    border-right: none;
  }
}

/* -------------start---------Rahul------------------------------- */

@media (min-width: 360px) and (max-width: 450px) {
  /* .carousel.slide {
      /* height:0 !important;  */

  .pb-3 {
    padding-top: 50px !important;
  }

  /* .iconLP02 a {
    display: none;


  } */

  .mywalluserDetail p {
    font-size: 13px !important;
  }

  .profileext p {
    font-size: 13px !important;
  }
}

@media (min-width: 740px) and (max-width: 800px) {
  .newupgrade_btn {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  /* .slider-course-listing .slick-slide {
    width: 10rem !important;
  }

  .slider-course-listing .slick-slide {
    width: 20.75rem !important;
  } */

  .jn-section {
    padding-left: 5%;
  }

  .mywalluserDetail p {
    font-size: 13px !important;
  }

  .profileext p {
    font-size: 13px !important;
  }

  .center_line {
    margin-top: 100px !important;
  }
}

@media screen and (min-width: 1870px) and (max-width: 1920px) {

  /* .popup-home {
    right: 11.2% !important;

  }

  .popup-home02 {
    right: 28.1%;
  } */
  .stick-top {
    top: 5.6rem;
  }

  #carouselExampleIndicators .carousel-inner .carousel-item img {
    height: 389px;
  }

  /* .mt-1 img {
    height: 803px !important;
  } */

  /* .content-SkipExplore {

    padding: 6px 0px;

  } */

  .btn-primary-startNow {
    margin-top: 20px;
  }

  .slider-card button.btn.btn-primary-startNow {
    margin-top: 0;
  }

  .footer-title {
    bottom: 4rem;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .livestrembtn {
    display: block !important;
    margin-top: 0px;
  }

  .btn-bg {
    padding: 9px 29px !important;
  }

  nav .content-logo .navbar-brand img {
    display: none;
  }

  .cal_iconLR button svg {
    display: none;
  }
  .calen-section .cal_iconLR button svg {
    display: block;
}
  .text-white {
    padding: 0px 11px;
  }

  .calen-section {
    margin-top: 10px !important;
  }

  .New-dashboard {
    margin-top: 10px !important;
  }

  .mainHEADING {
    font-size: 1.3rem;
    margin-bottom: 0px;
  }

  .Upcoming-bg-color {
    padding: 0 10px !important;
  }

  #carouselExampleIndicators p span {
    font-size: 0.8rem !important;
  }

  .My_Dashboard h3 {
    font-size: 1.5rem;
  }

  .carousel-item .text-white .mb-4 {
    margin-bottom: 8px !important;
  }

  section .container .My_dashboard a button svg {
    width: 24px !important;
    height: 24px !important;
  }

  .accordion-item .weektable-acc {
    overflow-x: auto;
    margin-top: 10px;
    max-height: 32.125rem;
  }
  .livebutton {
    padding: 2px 0.4rem;
  }
  .myOnboarding {
    display: block !important;
  }

  .myOnboarding a {
    position: relative;
    left: 5%;
    top: 0px;
  }

  .myOnboarding h3 {
    display: inline;
    font-size: 1.5rem;
  }

  .moDeviceAvtaar {
    display: block;
  }

  .fab_bar {
    display: none;
  }

  .gth li a {
    font-weight: bold;
    font-size: 14px;
    /* font-size: 18px; */
  }


  .btn-infoMonileDevice {
    border: solid 3px transparent;
    border-radius: 2px;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-image-source: linear-gradient(90deg,
        var(--color-element-1),
        var(--color-element-2));
    border-width: 2px;
    border-image-slice: 1;
    font-size: 0.8rem;
    font-weight: 700;
    font-family: Verdana;
    padding: 1rem 1.313rem;
    color: #000;
  }

  .text_Dashboard {
    font-size: 1.188rem;
    margin-bottom: 0;
  }

  .paraGraph_Dashboard {
    color: #000;

    font-size: 0.8rem !important;
    font-weight: normal;
  }

  .navbar_custom a {
    border-bottom: unset;
    font-size: 14px;
    color: #000;
    font-weight: bold;
  }

  .mt24 {
    margin-top: 24px;
  }

  /* .navbar-nav .dropdown-menu {
    top: -5px !important;
    left: -145px !important;
  } */

  .navbar-nav ul .gth {
    top: 23px !important;
  }

  /* .header_img_left h6 {
    font-size: 1.188rem;
    font-weight: 700;

    width: 241px;

    position: relative;
    bottom: 79px;
  } */

  .realtor__safety {
    height: 250px;
  }

  .main_realtor h6 {
    font-size: 1.188rem;
  }

  .container-chart .box .chart-8 {
    position: relative;
    width: 62px;
    height: 100%;
    text-align: center;
    font-size: 1rem;
    line-height: 50px;
    color: #1a3a4c;
    padding: 17px 0px;
  }

  .box {
    margin-right: 4.875rem;
  }

  .modules-menu .box {
    margin-right: 2.375rem;
  }

  .main_realtor p {
    font-family: Verdana;
    font-size: 1.125rem;
    color: #000;
    font-weight: 400;
  }

  .circularProgress {
    display: block;
    margin: 0 auto;
  }

  .menu-cont {
    margin-top: unset;
  }

  .box .cust_chart p {
    font-size: 0.425rem !important;
  }

  .cal_iconLR p {
    padding-left: 0;
  }

  .button-reset {
    padding-left: 0;
  }

  .slick-next {
    right: -15px;
  }

  .slick-prev {
    left: -15px;
  }

  .button-reset.sharebtn {
    padding-right: 6px;
  }

  .search__input .button-reset {
    padding-left: 10px;
  }

  .jba-toolkit-module-item .icon.cursor-pointer .button-reset {
    padding-right: 0;
  }

  .modules-menu .button-reset {
    padding-left: 7px;
  }


  .nav-link {
    border-bottom: none;
  }


  .accordion-item .cal_iconLR {
    margin-bottom: 0px !important;
  }

  .info-bottm-cardRealtor p {
    font-size: 1rem;
  }

  .course-head-al_search {
    font-size: 1.125rem;
  }

  .labelsearchRealtor {
    font-size: 1.188rem;
  }

  .container .module-box {
    width: 50%;
  }

  .mt117 {
    margin-top: 117px;
  }

  .home-footer_newModule {
    margin-top: 140px;
  }

  .galleryImg {
    height: 200px;
    object-fit: cover;
  }

  .galleryImghovr {
    height: 100px;
  }

  .dashboard-calendar .container-chart .box .chart-2 {
    margin-left: -50px;
  }

  .profile-left-sec {
    margin-top: 10px;
  }

  .shopping .profile-left-sec {
    margin-top: 0px;
  }

  .home-footer_newPayment {
    padding: 0.938rem;
    background: var(--color-element-1);
    color: #bebcbc;
    border-top: 2px solid #848586;
    margin-top: 575px;
  }

  .profile-banner {
    padding-left: unset;
    justify-content: center !important;
    margin-top: 32px !important;
  }

  .profile-show-sec {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .img-profile_visbty {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .prfile-obx {
    margin-right: 15px;
    margin-left: 0 !important;
  }

  /* .profile-left-section {

    padding: unset;
  } */

  .edit_profile_input {
    width: calc(100% - 0px);
    margin-left: 0;
  }

  .content_profile_pg.profile_containerone .container-chart {
    width: 50%;
  }

  .content_profile_pg.profile_containerone {
    display: flex;
    height: 100%;
  }

  .container-pie-chart {
    gap: 0px;
    flex-wrap: wrap;
  }

  .content_prof {
    margin-top: 50px;
  }

  .d-flex.flexboxprof {
    flex-wrap: wrap;
  }

  /* .certify-details p {
    margin: 1rem 0;
  }

  .certify-text .certify-text__title {
    font-size: 1.2rem;
  } */

  .content_profile_pg.profile_containerone .content_prof {
    margin-top: 0;
    width: 100%;
  }

  .headertitle.justify-space-between {
    justify-content: space-between;
  }

  .container-pie-chart .chart-1 {
    padding-left: 0 !important;
    justify-content: flex-start;
    gap: 10px;
  }

  .content_profile_pg.profile_containerone .icon-image .button-reset {
    padding-right: 0;
  }

  #dashboardId .content-progress {
    justify-content: space-between;
  }

  .profile-right-sec .content_profile_pg.profile_containerone .required {
    margin-top: 30px;
  }

  .profile-users02 {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    position: relative;
    top: 7px;
    padding: 22px 0px;
  }

  .edit_profile_input .col-md-6.mb-5.mt-3 {
    margin-bottom: 0 !important;
  }

  .profile-right-sec .row.mt-5 {
    margin-top: 0 !important;
  }

  .edit_profile_input.userdetslsd .input-text:nth-child(1) {
    padding-top: 0;
    /* margin-bottom: 1.25rem; */
  }

  .processnpxf .statusshowsec p .statusaccepted {
    margin-right: 5px;
  }

  .processnpxf .statusshowsec {
    padding-left: 7px;
  }

  .edit_profile_input.nffgh .col-md-6.mt-3 {
    margin-top: 0 !important;
  }

  .edit_profile_input.nffgh .col-md-6.mt-3 .input-text {
    padding-top: 0;
  }

  .editProfileSEC {
    display: none;
  }

  .post-grid>div>div>.MuiGrid-spacing-xs-1>.MuiGrid-item {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  .addfoldermodal .react-fine-uploader-gallery-dropzone-content, .addfoldermodal .react-fine-uploader-gallery-nodrop-content {
    font-size: 20px;
}
}

/* -------------end--------Rahul------------------------------- */

@media (min-width: 992px) {

  .nobannerimage {
    height: 350px;
    /* background: rgb(119 77 159 / 19%); */
  }
}

@media (min-width: 1201px) {
  .content-logo.mobileViewIcons {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {

  .card_image img {
    height: 13rem;
  }

  .event-without-img {
    height: 13rem;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {

  #courseCardCarousel .carousel-indicators,
  #cartCardCarousel .carousel-indicators {
    bottom: 51%;
  }
}

@media (min-width: 1500px) and (max-width: 1800px) {
  .card_image img {
    height: 13rem;
  }
}

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 991px) {
  .moDeviceAvtaar .dropdown-menu {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .navbar-nav.profile_thumbImg.moDeviceAvtaar .profile-nav .dropdown-menu {
    max-height: 15.625rem;
  }

  .navbar-nav.profile_thumbImg.host_inst_login .dropdown-menu {
    height: auto;
  }

  /* width */
  .profile-nav .dropdown-menu::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .profile-nav .dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .profile-nav .dropdown-menu::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg,
        var(--color-element-1),
        var(--color-element-2)) !important;
    border-radius: 10px;
    opacity: 0.9;
  }

  /* Handle on hover */
  .profile-nav .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(0deg,
        var(--color-element-1),
        var(--color-element-2)) !important;
    opacity: 1;
  }

  .notification-nav .scroll-nofify {
    max-height: 8.5rem;
    overflow-y: scroll;
  }

  .content-logo.mobileViewIcons .notification-nav .dropdown-menu {
    left: -12.5rem !important;
  }
}
