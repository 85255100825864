/* x-cel new introduction page*/
:root {
    --color-element-1: #00b7f1;
    --color-element-2: #ed156e;
}

.intro-accordian .accordion-button {
    border: solid 3px transparent;
    border-radius: 2px;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-image-source: linear-gradient(90deg, var(--color-element-1), var(--color-element-2));
    border-width: 2px;
    border-image-slice: 1;
    font-family: Verdana;
    font-weight: bold;
    padding: 13px 20px;
}

.last-section .placeholder_text input {
    background: transparent linear-gradient(90deg, var(--color-element-1) 0%, var(--color-element-2) 100%) 0% 0% no-repeat padding-box !important;
    background: transparent linear-gradient(90deg, var(--color-element-1) 0%, var(--color-element-2) 100%) 0% 0% no-repeat padding-box;
}

.intro-accordian .accordion-item .accordion-button {
    margin-top: 0px;
    margin-top: 0px;
    font-family: Verdana;
    font-size: 14px;
    color: #1A3A4C;
}

.intro-accordian img {
    width: 100%;
}

.homeaccordian .accordion-body {
    background: #Fff;
}

.intro-accordian .intro-button {
    background: transparent linear-gradient(90deg, var(--color-element-1) 0%, var(--color-element-2) 100%) 0% 0% no-repeat padding-box !important;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.accordion-button {
    color: #1a3a4c;
}

.accordion-button::after {
    background: #fff0;
}

.accordion-button:not(.collapsed) {
    color: #1a3a4c;
}

/* .last-section input ,.btn-primary {
    margin: 10px;
    border: solid 3px transparent;
    border-radius: 2px;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-image-source: linear-gradient(90deg, var(--color-element-1), var(--color-element-2));
    border-width: 2px;
    border-image-slice: 1;
    font-size: 14px;
    font-weight: bold;
    font-family: Verdana;
    padding: 16px 21px;   
} */
.last-section .submit button {
    padding: 15px 50px 15px 50px;
    background: #1A3A4C;
    border-color: #1A3A4C;
    color: #1a3a4c;
}

.openbtn {
    width: 32px;
    height: 32px;
    background: #fff;
    color: var(--color-element-1);
    font-weight: bold !important;
    border-radius: 50%;
    font-size: 18px;
    border: solid 3px transparent;
    border-radius: 50px;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    /* border-image-source: linear-gradient(90deg, var(--color-element-1), var(--color-element-2)); */
    border-width: 2px;
    border-image-slice: 1;
    /* line-height: 18px; */
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    box-sizing: content-box;
}
.openbtn span {
    background: var(--color-element-1);
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -10px;
}
.openbtn span:before {
    position: absolute;
    width: 20px;
    height: 2px;
    background: var(--color-element-1);
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.openbtn span:after{position: absolute;width: 20px;height: 2px;background: var(--color-element-1);border-radius: 4px;-webkit-transition: all 0.3s;transition: all 0.3s;}
.openbtn span::before {
    top: 6px;
}
.openbtn span::after {
    bottom: 6px;
}
.openbtn span::before, .openbtn span::after {
    left: 0;
    content: "";
}
.openbtn .closetoogle.closeopen{     
    background: transparent;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
   }
   .openbtn  .closetoogle.closeopen::before {
    -webkit-transform: translateY(-6px) rotate(45deg);
    transform: translateY(-6px) rotate(45deg);
}
.openbtn  .closetoogle.closeopen::after {
    -webkit-transform: translateY(6px) rotate(-45deg);
    transform: translateY(6px) rotate(-45deg);
}
.open {
    margin-right: 20px;
}
.open.fixetooglebtn {
    border-radius: 0 20px 20px 0;
    background: #fff;
    width: fit-content;
    padding: 5px;
    position: fixed;
    margin-top: 12px;
    box-shadow: 5px 0px 5px rgb(0 0 0 / 24%);
    z-index: 9;
}
.intro_sidenav {
    background-color: #fff !important;
    width: 450px !important;
}

.intro_sidenav .intro_navitem button,
.intro_navitem {
    color: #000 !important;
    font-weight: 800;
    font-size: 24px;
    justify-content: space-between !important;
}

#mySidenav .intro_navitem button.active {
    border-bottom: 3px solid var(--color-element-1) !important;
}

.sidenav_heading h1 {
    font-size: 13px !important;
    color: #000;
    font-family: Verdana;
    font-weight: bold;
}

.sidenav_para {
    font-size: 8px !important;
    color: #000
}

.sidenav_Instruction_text {
    font-size: 15px;
}

.sidenav_title {
    color: #000 !important;
}

.sidenav_chart-2 {
    width: 40px;
}

.content-text {
    background: #F1F1F1;
    border-radius: 5px;
    padding: 15px 13px 19px;
    font-size: 18px;
    font-family: Verdana;
    line-height: 22px;
    text-align: left;
}

/* .slick-slide {
    width:100vw !important;
} */
.slick-dots {
    background: unset !important;
    height: auto;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 37px !important;
    height: 7px !important;
    border-radius: 5px;
    padding: 3px !important;
    cursor: pointer;
    border: 2px solid #000 !important;
    outline: none;
    background: #fff !important;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: -8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px !important;
    height: 7px !important;
    content: " " !important;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 35px !important;
    height: 10px !important;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    border-radius: 5px;
}

li.slick-active {
    background: transparent linear-gradient(90deg, var(--unnamed-color-00b7f1) 0%, var(--unnamed-color-ed156e) 100%) 0% 0% no-repeat padding-box !important;
    background: transparent linear-gradient(90deg, var(--color-element-1) 0%, var(--color-element-2) 100%) 0% 0% no-repeat padding-box !important;
}

.slick-slide {
    opacity: 1 !important;
}

.image-section {
    margin-bottom: 10px;
}

.m-head-intro {
    margin-top: 45px;
}

.introduction-text-icon {
    margin-bottom: 50px;
}

.m-head-introduction-text {
    flex-direction: column;
}

.m-head-introduction-text h1 {
    font-family: Verdana;
    font-weight: bold;
    font-size: 26px;
}

.video_img {
    background: url(/public/images/Untitled-5.jpg);
    height: 70px;
    margin-bottom: 2px;
}
.video_img .text-white{
    margin-bottom:0
}
.intro-question {
    font-size: 18px;
    font-family: Verdana;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 0px;
}

.container-intro {
    margin: 0;
    padding: 0;
}

.intro-row {
    margin-left: 0;
}

.text-white {
    padding: 46px 0px;
}

.text-white h1 {
    font-size: 50px;
    font-family: Verdana;
    font-weight: normal;
    line-height: 30px;
}

.progress-detail {
    border-radius: 0px 5px 5px 0px;
}

/* .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 0px;
} */

.form-group-intro {
    margin-top: 10px;
}

input#pwd {
    height: 100px;
    border: solid 3px transparent;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, var(--color-element-1), var(--color-element-2));
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-image-source: linear-gradient(90deg, var(--color-element-1), var(--color-element-2));
    border-width: 2px;
    border-image-slice: 1;
}

.icon-intro img {
    height: 26px;
    margin-left: 7px;
}

.icon-intro {
    justify-content: flex-end;
    margin-top: 10px;
}

.step-complete-note::before {
    border: 2px solid #6EA943;
    color: #6EA943;
    border-radius: 100px;
    content: "\f00c";
    font-family: 'fontawesome';
    padding: 3px;
}

/* .main {
    transition: margin-left 0.5s ease;
} */

.box {
    margin-right: 30px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 20px;
}
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.switch .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

  
.switch .slider.round {
    border-radius: 10px;
    border: solid 1px transparent !important;
    background-image: linear-gradient(#bebec5, #fff), linear-gradient(90deg, var(--color-element-1),var(--color-element-2) ) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
    /* border-image-source: linear-gradient(90deg, var(--color-element-1),var(--color-element-2)) !important; */
    border-width: 2px !important;
    border-image-slice: 1 !important;
    border-radius: 1.875rem;
}

.switch input:checked + .slider {
    background-image: linear-gradient(90deg,var(--color-element-1),var(--color-element-2)),linear-gradient(90deg,var(--color-element-1),var(--color-element-2))!important;
}
.switch input:checked +.slider:before {
    content: " ";
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}
.checklistboxdata .usr-title {
    font: normal normal bold 22px/27px Verdana;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 6px;
}

.checklistboxdata .tablebox {
    margin-top: 10px;
    max-height: 12.5rem;
}

.addfoldermodal .fine-uploader-dropzone-container.react-fine-uploader-gallery-dropzone, 
.addfoldermodal .react-fine-uploader-gallery-nodrop-container {
    display: inline-block;
    margin-top: 8px;
    border-radius: 3px;
    border: 2px dashed #fff !important;
    background: linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, var(--color-element-1) 15%, var(--color-element-2)90%) border-box !important;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.addfoldermodal .react-fine-uploader-gallery-file-input-container {
    width: 153px;
    margin: auto;
    display: block;
    float: none;
    color: #fff;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    background: transparent linear-gradient(90deg, var(--color-element-1) 0%, var(--color-element-2) 100%) 0% 0% no-repeat padding-box;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.addfoldermodal .react-fine-uploader-gallery-file-input-upload-icon {
    margin-bottom: 0;  
}
.addfoldermodal .react-fine-uploader-gallery-file-input-container:hover{
    border: none;
    box-shadow: none;
    background: transparent linear-gradient(90deg, var(--color-element-1) 0%, var(--color-element-2) 100%) 0% 0% no-repeat padding-box;
     
}
.addfoldermodal .react-fine-uploader-gallery-dropzone-content, .addfoldermodal .react-fine-uploader-gallery-nodrop-content {
    top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
 