.slider {
    width: 100%;
    /* margin: 30px auto; */
}

.slick-slide {
  margin: 0px 20px;
}

/* .slick-slide img {
  width: 100%;
} */

.slick-prev:before,
.slick-next:before {
  color: black;
}


.slick-slide {
  transition: all ease-in-out .3s;
  opacity: .2;
}

.slick-active {
  opacity: .5;
}

.slick-current {
  opacity: 1;
}

/* custom */
.slick-slide {
    margin: 0px 5px;
}
.cardheading-course {
    padding: 14px;
    background: #B1B1B1;
    margin-bottom: 2px;
    position: relative;
}
.details-cards-oll {
    padding: 5px 15px 20px;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
	box-shadow: 2px 2px 5px gray;
	margin-bottom: 5px;
    height: 153px;
}
.details-cards-product {
    padding: 5px 15px 20px;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
	box-shadow: 2px 2px 5px gray;
	margin-bottom: 5px;
    height: 70px;
}
.details-cards-oll .progress {
    height: 5px;
    background-color: #E5E5E5;
    border-radius: 5px;
} 

.details-cards-oll .progress1 {
    height: 5px;
    background-color: #1A3A4C;
    border-radius: 5px;
} 
.inprogress-box .cardheading-course {
    background-color: #FF6464;
    color: #fff;
}
.inprogress-box .progress-bar { background-color: #00B70E;}
.completed-box .progress-bar { background-color: #00B70E;}
.details-cards-oll h5{
    font-family: 'museo700';
    margin-top: 10px;
    min-height: 50px;
    color: #1A3A4C;
}
.info-bottm-card p {
    font-size: 12px;
    color: #1A3A4C;
    margin-bottom: 0;
}
.actionicons {display: flex;
    justify-content: end;
}
.actionicons i{
    width: 30px;
    height: 30px;
    background: #1a3a4c;
    color: #fff;
    border-radius: 50%;
    padding: 8px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
}
.actionicons i:last-child{margin-right: 5px;}
.car-footer-details {font-size: 12px; color: #1a3a4c; margin-top: 10px;}
.car-footer-details p{margin-bottom: 0; font-family: 'museo700';}
.cirprogress {
    display: flex;
    justify-content: left;
    align-items: center;
}
.cirprogress .sectionheading{color: #1a3a4c; font-family: 'museo700';}
.slick-dots {
    background: #1A3A4CCC;
    border-radius: 20px;
    bottom: -30px;
    height: 10px;
}
.slick-dots li button {
    width: 61px;
    height: 32px;
}
.slick-dots li {
    width: 60px;
    height: 9px;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 9px;
}
.course-complete {
    position: absolute;
    right: -10px;
    top: 15px;
    justify-content: center;
}
.course-complete::before {
    color: #191919;
    background: #00B70E;
    border: 4px solid #191919;
    font-size: 1.1rem;
    line-height: 7px;
    font-weight: 900;
    content: "\f00c";
    font-family: 'fontawesome';
    padding: 3px;
    border-radius: 50%;
}
.connectingline {
    border: 3px solid #000;
    width: 30px;
    margin-top: -15px;
    margin-left: 30px;
}
/* .slick-active .complete-course-sl {opacity: 1;} */
.allactiveslid .slick-active { opacity: 1;}
.slick-track {margin-left: 0 !important;}
.slider-img-inner {border-radius: 10px 10px 0px 0px;}
.allactiveslid .video-box video {border-radius: 10px 10px 0px 0px;}
