/* @font-face {
  font-family: Verdana;
  src: url('Verdana.eot') format('eot'),
	 url('verdana.svg') format('svg'),
	 url('verdana.ttf') format('ttf'),
 	 url('verdana.otf') format('otf');
	
} */
/* @font-face {
  font-family: Verdana-bold;
  src: url('verdana-bold.ttf') format('ttf'),
} */


@font-face {
  font-family: 'fontawesome';
  src: url('fontawesome-webfont.eot');
  src: url('fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
      url('fontawesome-webfont.woff2') format('woff2'),
      url('fontawesome-webfont.woff') format('woff'),
      url('fontawesome-webfont.ttf') format('truetype'),
      url('fontawesome-webfont.svg#fontawesome-webfont') format('svg');
}

@font-face {
  font-family: 'Verdana';
  src: url('./Verdana.eot') format('eot'),
	 url('./verdana.svg') format('svg'),
	 url('./verdana.ttf') format('ttf'),
   url('./Verdana.woff2') format('woff2'),
      url('./Verdana.woff') format('woff'),
 	 url('./verdana.otf') format('otf');
	font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: 'Verdana';
  src: url('Verdana-Bold.eot');
  src: url('Verdana-Bold.eot?#iefix') format('embedded-opentype'),
      url('Verdana-Bold.woff2') format('woff2'),
      url('Verdana-Bold.woff') format('woff'),
      url('Verdana-Bold.svg#Verdana-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
