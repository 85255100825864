lea:root {
  --color-element-1: #00b7f1;
  --color-element-2: #ed156e;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
.active-module {
  background-color: #0087cc4f;
  padding-top: 1px;
}
.active-module-heading {
  background-color: #0087cc4a;
  padding-top: 1px;
}  
.submitheader, .submit--feedback {
  background: var(--color-element-1);
  border-radius: 0px;
}

.active-activity {
  background-color: #0087cc1f;
  padding-top: 1px;
}

.bottom-fixed {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  /* background-color: transparent; */
  background-clip: padding-box;
  border: 0px;
  border-radius: 0.3rem;
  outline: 0;
}

.invoice-modal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px;
  border-radius: 0.3rem;
  outline: 0;
}

.invoice_cust_container .center_line {
  margin-top: 0px !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.modal-backdrop.show {
  opacity: 0.5 !important;
}

.circularProgress .CircularProgressbar .CircularProgressbar-text {
  fill: #000000 !important;
  font-size: 9px !important;
  dominant-baseline: middle;
  text-anchor: middle;
}

.mr-2 {
  margin-right: 0.5rem;
}

.nav-item.show {
  background: #fff;
  color: #000;
  /* border-bottom: 3px solid var(--color-element-1); */
}

.navbar-nav .dropdown-menu {
  top: 25px !important;
}

.profile-nav .dropdown-menu {
  top: -12% !important;
  height: fit-content;
}

.container-fluids {
  background: #fff;
}

.center_line.mt-3 {
  width: 100%;
  margin-top: 5rem;
}

.profile-left-sec {
  /* width: 100vw; */
  /* padding: 0 140px !important; */
}

.profile-left-sec.learning {
  margin-bottom: 0px !important;
}

.premium-diamond {
  top: 0rem;
  right: 0.05%;
  height: 1.2rem;
  width: 1.2rem;
  border: 2px solid white;
  border-radius: 50%;
}

.premium-diamond span {
  background: transparent linear-gradient(90deg, #00b7f1, #ed156e) 0 0 no-repeat
    padding-box;
  background: transparent
    linear-gradient(
      90deg,
      var(--color-element-1) 0,
      var(--color-element-2) 100%
    )
    0 0 no-repeat padding-box;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  display: inline-block;
  text-align: center;
  line-height: 1rem;
  font-size: unset !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-content {
  margin-right: 1rem;
}

/* .slider-course-listing .slick-slide {
  width: 19rem !important;
} */
/* .slider-course-listing .slick-slide {
	margin: 0 80px 0 -60px !important;
}

.slider-course-listing .slick-list {
	margin: 0 0px 0 0px;
} */
.My_Dashboard.New_My_Dashboard.icon_MY_DB .button-reset,
.My_Dashboard.New_My_Dashboard .button-reset {
  position: relative;
  left: 18px;
}

.slider-course-listing .slick-prev,
.slick-prev,
.slick-next {
  width: auto !important;
  height: auto !important;
}

.slider-course-listing .slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.cal_iconLR {
  margin-bottom: 10px;
}

.profile-nav a::after,
.notification-nav a::after {
  content: "";
  display: none;
}

/* .broker-box {
  padding-top: 1em;
} */

.custom_bartwoRealtor {
  height: 0.6rem !important;
}

.border_lines .progressbar {
  width: 100%;
  height: 0.6rem;
}

.custom_bartwoRealtor .progress-bar,
.progressbar .progress-bar {
  background-color: #00b70e !important;
  border: 1px solid #fff;
  border-radius: 5px;
}

.slick-prev::before,
.slick-next::before {
  color: black !important;
}

.input-lear .close-search-text {
  position: absolute;
  right: 28%;
  top: 3%;
}

.input-lear .hide {
  display: none;
}

.learning {
  background-color: #fff;
}

/* Home Page */
.center_line.m-0 {
  margin: 0px !important;
}

/* End of Home Page */

/* Learning Paths */
#courseCardCarousel,
#cartCardCarousel {
  position: relative;
  height: 100%;
}

#cartCourseCarousel.carousel.slide {
  height: 100%;
}

#courseCardCarousel .carousel-indicators,
#cartCardCarousel .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 51%;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5rem 0;
  list-style: none;
  /* background: rgb(0 0 0 / 24%); */
  background-color: rgb(0 0 0 / 47%);
}

#cartCardCarousel .carousel-indicators {
  bottom: 0%;
  justify-content: flex-end;
  padding-right: 0.8rem;
}

/* .image-cart {
  width: 35%;
} */

#courseCardCarousel .carousel-indicators [data-bs-target],
#cartCardCarousel .carousel-indicators [data-bs-target] {
  padding: 0;
  height: 0.375rem;
  border-radius: 0.625rem;
  background: transparent;
  border: 0.125rem solid #fff;
  width: 1rem;
  opacity: 1;
}

#cartCardCarousel .carousel-indicators [data-bs-target] {
  width: 0.4rem;
}

#courseCardCarousel .carousel-indicators .active,
#cartCardCarousel .carousel-indicators .active {
  opacity: 1;
  background: #fff;
  /* margin-left: 0.313rem;
  margin-right: 0.313rem; */
  background: transparent
    linear-gradient(
      90deg,
      var(--unnamed-color-00b7f1) 0,
      var(--unnamed-color-ed156e) 100%
    )
    0 0 no-repeat padding-box;
  background: transparent
    linear-gradient(
      90deg,
      var(--color-element-1) 0,
      var(--color-element-2) 100%
    )
    0 0 no-repeat padding-box;
}

#courseCardCarousel .carousel-control-prev,
#cartCardCarousel .carousel-control-prev {
  visibility: hidden;
}

#courseCardCarousel .carousel-control-next,
#cartCardCarousel .carousel-control-next {
  visibility: hidden;
}

/* #shoppingCardCarousel .carousel-indicators {bottom: 54%} */
.learning-modal .modal-backdrop {
  background-color: #00000073;
}

.learning-modal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.learning-modal .modal-40w {
  max-width: 50vw;
  width: 30vw;
}

.learning-modal .grid-column,
.forgot-password .grid-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.learning-modal .grid-column .image-area img,
.forgot-password .grid-column .image-area img {
  width: 100%;
  height: 100%;
}

.learning-modal .info_Content__txt {
  padding: 1rem;
}

.learning-modal .info_Content__txt p {
  padding: 0px;
}

.learning-modal .info_Content__txt ~ div button {
  width: 100%;
}

.learning-modal .info_Content__txt .cancel-icon {
  position: absolute;
  top: 1px;
  right: 3px;
}

/* Header */
.unset-a {
  color: unset;
  text-decoration: unset;
}

/* End of Header */

/* Module Detail */

.grayscale {
  filter: grayscale(100%);
}

.lock-block {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lock-block img {
  width: 2rem;
}

/* Course Launch */
.border-info {
  border: solid 3px transparent;
  border-radius: 2px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) ;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  );
  border-width: 2px;
  border-image-slice: 1;
  padding: 0.8rem;
}

.sidenav_title.active {
  font-weight: bold;
}

.notesModule_icon {
  width: 2rem;
  cursor: pointer;
}

.fav-title-index {
  margin: 0;
  line-height: 1.8rem;
}

.note-thumb {
  display: flex;
  gap: 0;
  margin: 0px 0px 0 0;
}

.thumb-image {
  justify-content: space-between;
  /* margin-left: 0.375rem; */
}

.print-icons {
  display: flex;
}

p.notes-desc {
  background: #f1f1f1;
  padding: 0px 10px 0px 25px;
  border-radius: 5px;
  font-size: 1rem;
  font-family: Verdana;
  font-weight: normal;
}

.notes-box p.notes-desc {
  padding: 5px;
  margin-left: 0;
}
#notes .row .thumb-image {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
#notes .row .thumb-image .titlesecnotes {
  margin-left: 0;
}
/* .text-area {
  padding: 0px 3px 0px 8px;
} */
#notes .row .thumb-image .titlesecnotes {
  margin-left: 0;
}

/* .text-area .notes-desc{
  outline: unset;
  display: block;
  width: 100%;
  border: solid 3px transparent;
  border-radius: 2px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  );
  border-width: 2px;
  border-image-slice: 1;
  padding: 0.313rem 0.938rem;
  width: 100%;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
  font-weight: 400;  
  white-space: pre-line;
  word-break: break-all;
} */
.notes-desc {
  white-space: pre-line;
  outline: unset;
  display: block;
  width: 100%;
  border: solid 3px transparent;
  border-radius: 2px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  );
  border-width: 2px;
  border-image-slice: 1;
  padding: 0.313rem 0.938rem;
  width: 100%;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
  font-weight: 400;
  word-break: break-word;
}

.fav-thumb img {
  height: 16rem;
  width: 100%;
  object-fit: contain;
}

.course,
tool .thumb-image p,
.thumb-image {
  font-size: 24px;
  font-weight: bold;
  font-family: Verdana;
  line-height: 40px;
}

.module-title {
  font-size: 16px;
  font-weight: bold;
  font-family: Verdana;
}

.module-item-container span {
  font-family: Verdana;
  font-weight: normal;
  line-height: 24px;
  font-size: 14px;
}
.modules-menu.mb-3 ul.module-list {
  margin-bottom: 0;
}

.course-text,
.module-item-container {
  align-items: center;
}

/* .module-item-container {
  margin-top: -23px;
} */

.fade.alert-modal.modal.show {
  padding: 70px;
}

button.confirm.btn.btn-primary,
button.confirm.btn.btn-secondary {
  border: 2px solid var(--color-element-1) !important;
  border-radius: 2px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  );
  border-width: 2px;
  border-image-slice: 1;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: Verdana;
  padding: 0.4rem 1.313rem;
  color: #fff;
}

button.confirm.btn.btn-msg {
  border: 2px solid var(--color-element-1) !important;
  border-radius: 2px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  );
  border-width: 2px;
  border-image-slice: 1;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: Verdana;
  padding: 0.4rem 1.313rem;
}

.skipBtn:hover,skip-button:hover {
  background:var(--color-element-1) !important;
  border: 1px solid var(--color-element-1) !important;
  color: #fff !important;
}


button.confirm.btn.btn-primary:hover,
button.confirm.btn.btn-secondary:hover {
  background: transparent !important;
  border: 2px solid var(--color-element-1) !important;
  /* background-clip: padding-box, border-box;
  background-image: linear-gradient(
      90deg,
      var(--color-element-1),
      var(--color-element-2)
    ),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-origin: border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important; */
  color: #000 !important;
}

.modules-menu-item.d-flex {
  align-items: center;
  position: relative;
}

/* End of Course Launch */

/* Forgot password */
/* End of Forgot password */

/* Login Page */
.login-msg div {
  font-weight: bold;
  font-size: 0.875rem;
}

.forgot-pwd:hover {
  color: #000;
}

.h-16 {
  height: 1rem;
}

.h-16 div {
  font-size: 0.8rem;
}

.isLogin span {
  background-clip: padding-box, border-box;
  background-image: linear-gradient(
      90deg,
      var(--color-element-1),
      var(--color-element-2)
    ),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-origin: border-box;
  border: 2px solid;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important;
  color: #fff;
  margin: 0rem 0.8rem;
}

/* End of login page */

.My_Dashboard05 a {
  position: relative;
  left: 18px;
}

.My_Dashboard05 h3 {
  font-size: 1.625rem;
  font-family: Verdana;
  font-weight: 700;
  color: #000 !important;
}

/* End of login page */
.common-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.spinner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* End of login page */

/* Edit Profile */

.profile-right-sec .input__field02 {
  font-weight: bold;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  background: white !important;
}

.css-u9osun {
  background: white !important;
}

.dropdown-item {
  border-bottom: 1px solid #dbd8d6 !important;
}

.css-segi59 {
  font-family: "Verdana";
  font-weight: 400;
  font-size: 1rem;
  line-height: 0.8rem;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 0px !important;
  padding-right: 14px;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Verdana";
  font-weight: 400;
  font-size: 1rem;
  line-height: 0.8rem;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 0px !important;
  padding-right: 14px;
}

.css-1c0iqou-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 0px !important;
}

.css-gs227a .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 0px !important;
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px !important;
}

.css-cktaik {
  top: -5px !important;
}

.has-value .MuiInputLabel-root {
  top: -3px !important;
}

/* for disabled lookups input box */
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #a9a9a9 !important;
}

.css-b52kj1.Mui-disabled {
  -webkit-text-fill-color: #a9a9a9 !important;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-b52kj1.Mui-disabled {
  font-weight: 700;
  -webkit-text-fill-color: #a9a9a9 !important;
  font-size: 0.875rem;
}

.input__fields__input {
  outline: unset;
  display: block;
  width: 100%;
  border: solid 3px transparent;
  border-radius: 2px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  );
  border-width: 2px;
  border-image-slice: 1;
  padding: 0.2rem 1rem;
  width: 100%;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
  font-weight: 400;
}

/* End of Edit Profile */

/* Start of My On boarding Section */
.my_onbording .accordion-item {
  background: unset;
}

/* End of My On boarding Section  */

/* In Progress section in home page */
/* .broker-box .disable_Card {
  height: 23.2rem;
} */

/* End of in progress section in home page */

.nav-item.show .dropdown-toggle.nav-link {
  border-bottom: 3px solid ;
}
/* .bg-menu-lightWhite .nav-item.item-underline:hover,
.bg-menu-lightWhite .nav-item.item-underline.active{
  border-bottom: 3px solid var(--color-element-1) !important;
} */
.left-arrow img {
  width: 26px;
  height: 26px;
  margin-left: 1rem;
}

span.title.sidenav_home {
  /* gap: 15px; */
  padding: 0px 31px;
  font-weight: bold;
}

div#modules {
  margin-left: 18px;
}

.notes-text p, .module-list.no-found-msg {
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  font-family: verdana;
  padding: 0px 0px;
  font-size: 0.875rem;
}

/* .favorites-tab-content.container.fav-text {
    margin-left: 7px;
} */
.fav-text p {
  margin-left: 16px;
  font-family: verdana;
  font-size: 0.875rem;
}

.help-text {
  position: relative;
  bottom: 11px;
}

/* .MuiOutlinedInput-input{
    padding-bottom: 0 !important;
    padding-top: 0px !important;
    margin-bottom: -10px !important;
}

.MuiInputLabel-root{
    position: absolute !important;
    top: -12px;
} */
.next-learn-items {
  display: grid;
}

.cong {
  margin-top: 10px;
}

/* .header_img_left {
  margin-top: 1.85rem;
} */

.header_img_left h6 {
  width: 48%;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 0px !important;
}

.css-vubbuv {
  width: 0px !important;
}

.left-arrow {
  margin-left: -5px;
}

/* Payment Page */
.stripe_field {
  padding: 0.32rem 1rem;
}

.color-red {
  color: #ff0000;
}

.pay_submit {
  width: 16rem;
}

.baner-section.home_page_bannner .carousel-item {
  position: relative;
}

.payment-form-section .input-text {
  margin-bottom: 1.25rem;
}

.payment-form-section .input__field,
.payment-form-section .input_searchfield {
  margin-bottom: unset;
}

.payment-form-section .error-message {
  height: 12px;
  font-size: 10px;
  color: #ff0000;
}

.payment-form-section label > span {
  color: #ff0000;
}

/* End of Payment Page */
.my-cart-text {
  margin-left: 120px;
  margin-top: 27px;
  font-size: 20px;
}

/* .cart-title .my-cart{
    color:#000;
    margin-top: 20px;
    margin-left: 122px;
} */
.cart-title p {
  color: #000;
  margin-top: 20px;
  margin-left: 122px;
}

/* .my_carts{
    width:856px;
} */

.clicked-btn span {
  border-color: black;
}

.cart_Card {
  position: relative;
  left: 94%;
  top: 25px;
  z-index: 10;
  width: 25px;
  border-radius: 50%;
}

.cross_Iconn img {
  position: relative;
  z-index: 1;
}

.button-reset {
  z-index: 1;
}

.cartCarousel {
  padding: 0;
  margin: 0;
}

.saved-text {
  width: 100%;
  display: inline-block;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  font-family: Verdana;
  margin-top: 25px;
}

.my-cart-page {
  min-height: 70vh;
}

.my-credit-points {
  min-height: 73vh;
}

.cart_discount span {
  font-size: 14px;
  font-family: Verdana;
  font-weight: bold;
  color: #1a3a4c;
  margin-bottom: 0;
}

.cartDiscountBtn {
  /* background-color: #a9c23c; */
  /* border-radius: 2px; */
  background-color: transparent;
  text-align: center;
  border: none;
}

.cartDiscountBtn span,
.discount-tile {
  padding: 6px 14px;
  color: #a9c23c !important;
  text-align: center;
  font-size: 12px;
  font-family: Verdana;
  font-weight: bold;
}

.cartContent p {
  margin-bottom: 0;
  color: #1a3a4c;
  font-weight: bold;
  font-size: 13px;
  font-family: Verdana;
}

.dollor19 {
  color: #aaa9aa;
  font-weight: bold;
}

.cartContent span {
  color: #aaa9aa;
  font-family: Verdana;
  font-size: 13px;
}

.cartContent input[type="checkbox"] {
  -webkit-appearance: none;
  background: transparent
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-origin: border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important;
  border-width: 2px !important;
  border-image-slice: 1 !important;
  height: 20px !important;
  width: 20px !important;
  border-radius: 3px;
  border: 2px solid;
  margin-right: 10px;
  cursor: pointer;
  /* position: relative;
  bottom: 10px; */
}

.active-checked::after {
  content: " ";
  width: 12px !important;
  height: 12px !important;
  background: linear-gradient(90deg, #29aadb, var(--color-element-2));
  border-radius: 25%;
  display: grid !important;
  /* place-content: center !important; */
  background-size: contain;
  position: relative;
  top: 2px;
  left: 2px;
}

/* Credit points CSS */

/* Learning Listing */
.course-length-bar {
  top: 2%;
  background: rgba(0, 0, 0, 0.64);
  color: white;
  right: 4%;
  z-index: 3;
  padding: 0.4rem;
  border-radius: 4px;
  font-size: 12px;
}

.course-length-bar span {
  font-weight: bold;
}

/* End of learning listing */

/* .pointer-events-table {
    padding-top: 8.375rem;
} */

.pointshead {
  background: transparent
    linear-gradient(
      90deg,
      var(--unnamed-color-ed156e) 0%,
      var(--unnamed-color-febe10) 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent
    linear-gradient(90deg, var(--color-element-2) 0%, #febe10 100%) 0% 0%
    no-repeat padding-box;
  padding: 0px 90px;
  height: 80px;
  align-items: center;
  justify-content: space-between;
}

.invoice_main_moda-point span {
  font: normal normal normal 23px/28px Verdana !important;
  color: #fff;
}

.invoice_main_moda-point img {
  display: inline;
  width: 84px;
  height: auto;
}

.invoice_main_moda-point {
  display: flex;
  align-items: center;
}

.invoice_main_moda-point span {
  padding-left: 5px;
}

.points-count {
  background: rgb(122 84 158 / 74%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: baseline;
}

.points-count .bs {
  font: normal normal bold 26px/26px Verdana;
  margin-right: 5px;

  color: #ffffff;
}

.points-count .pnts {
  font: normal normal normal 18px/26px Verdana;
  letter-spacing: 0px;
  color: #ffffff;
}

.points-box {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  padding: 17px 90px;
}

.responsive-carousel-points .slick-prev {
  left: 32px;
  top: 40px;
  z-index: 9;
}

.responsive-carousel-points .slick-next {
  right: 32px;
  top: 40px;
  z-index: 9;
}

.points-table .points-box:nth-child(even) {
  background: #e5e5e5 0% 0% no-repeat padding-box;
}

.points-table {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, var(--color-element-2), #febe10);
  border-image-slice: 1;
}

.col-8.leftpotsdat p {
  margin-bottom: 8px;
}

.orderid {
  font: normal normal normal 14px/17px Verdana;
  letter-spacing: 0px;
  color: #777779;
}

.coursename {
  font: normal normal normal 18px/22px Verdana;
  letter-spacing: 0px;
  color: #000000;
}

.datetime {
  font: normal normal normal 16px/19px Verdana;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 0 !important;
}

.userpoints {
  font: normal normal bold 24px/22px Verdana;

  text-align: right;
}

.userpoints.pluspoints {
  color: #0fb41c;
}

.userpoints small {
  font: normal normal normal 18px/22px Verdana;
}

.userpoints.minuspoints {
  color: var(--color-element-2);
}

.pointsdetails p {
  margin-bottom: 8px;
}

.pinds {
  font: normal normal bold 16px/19px Verdana;

  color: #777779;
}

.pindss {
  font: normal normal normal 16px/19px Verdana;
  letter-spacing: 0px;
  color: #777779;
}

.readmored {
  font: normal normal bold 16px/19px Verdana;
  color: var(--color-element-1);
  text-decoration: none;
}

.pointer-events-table {
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .pointshead {
    padding: 0 10px;
  }
  .points-table.padlist {
    padding-left: 10px;
    padding-right: 10px;
  }
  .invoice_main_moda-point img {
    width: 50px;
  }

  .invoice_main_moda-point span {
    font-size: 18px;
  }

  .points-count .bs {
    font-size: 18px;
  }

  .points-count .pnts {
    font-size: 15px;
  }

  .points-box {
    padding: 10px;
  }

  .coursename {
    font-size: 16px;
  }

  .datetime {
    font-size: 14px;
  }

  .userpoints {
    font-size: 22px;
  }

  .userpoints small {
    font-size: 16px;
  }

  .responsive-carousel-points .slick-prev {
    top: 50%;
    left: -9px;
  }

  .responsive-carousel-points .slick-next {
    top: 50%;
    right: 0px;
  }
  .mobile_calendar_section{
    display:block !important;
  }
  .desktop_calendar_section{
    display:none !important;
  }
  .popover_calendar_desktop{
    display:none !important;
  }
}

/* Learning Points*/

/* .pointer-history-events-table {
  padding-top: 8rem;
} */
.points-head-history h1 {
  font: normal normal normal 36px/44px Verdana;

  color: var(--color-element-1);
  margin-bottom: 48px;
}

.pointer-history-events-table tr:nth-child(even) td {
  background-color: #e5e5e5;
  border-color: #fff;
}

.pointer-history-events-table tr:nth-child(odd) td {
  background-color: #f7f7f7;
  border-color: #fff;
  --bs-table-accent-bg: #f7f7f7;
}

.pointer-history-events-table thead:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 6px;
  background: red;
  background-image: linear-gradient(
    to right,
    var(--color-element-2),
    #ff8600,
    #ffba00,
    #febe10
  );
}

.pointer-history-events-table table.table.table-striped {
  border-collapse: unset;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, var(--color-element-2), #febe10);
  border-image-slice: 1;
}

.pointer-history-events-table tbody,
.pointer-history-events-table td {
  font: normal normal normal 16px/19px Verdana;
}

.pointer-history-events-table th {
  font-size: 16px;
  line-height: 19px;
  border: none;
}

.pointer-history-events-table .table > :not(caption) > * > * {
  padding: 10px;
}

.pointer-history-events-table {
  padding-top: 3rem;
  padding-bottom: 60px;
}

.pointer-history-events-table .pointsbox-table.slick-slide {
  margin: 0 !important;
}

.pointer-history-events-table .slick-prev {
  left: -30px;
}

@media only screen and (max-width: 767px) {
  .pointer-history-events-table thead th {
    width: 165px;
  }

  .pointer-history-events-table table.table.table-striped {
    table-layout: fixed;
  }

  .pointer-history-events-table .pointsbox-table.slick-slide {
    margin: 0 25px !important;
    overflow-x: auto;
  }

  .pointer-history-events-table .slick-prev {
    left: 0px;
  }

  .pointer-history-events-table .slick-next {
    right: 0px;
  }
  .desktop_calendar_section{
    display:none !important;
  }
  .popover_calendar_desktop{
    display:none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .pointer-history-events-table th {
    font-size: 16px;
    line-height: 19px;
    border: none;
    vertical-align: top;
  }
  .mobile_calendar_section{
    display:block !important;
  }
  .desktop_calendar_section{
    display:none !important;
  }
  .popover_calendar_desktop{
    display:none !important;
  }
}

.pointsbox-table thead {
  position: relative;
}

.dollar-normal {
  font-weight: normal;
}

.cart-description-ex {
  border-bottom: 1px solid #aaa9aa;
  display: flex;
  margin-top: 10px;
  padding-bottom: 10px;
}

.combo-tag {
  position: absolute;
  top: 12px;
  background: #ed156e;
  z-index: 2;
  left: -3px;
  font-size: 12px;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
}

.d-flex.sub-total h4,
.d-flex.sub-total h2 {
  font-size: 1rem;
  line-height: 1.2rem;
}

.d-flex.sub-total h2 {
  font-weight: bold;
  text-align: right;
}

.d-flex.sub-total h2.color-red {
  color: rgb(249, 22, 22);
}

.color-white {
  color: #fff !important;
}

.setup-password .setup-button:hover {
  background-image: linear-gradient(
      90deg,
      var(--color-element-1),
      var(--color-element-2)
    ),
    linear-gradient(90deg, var(--color-element-1), var(--color-element-2)) !important;
  background-origin: border-box;
  border: 2px solid;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important;
  color: #fff !important;
}

.disclaimer-text {
  padding: 0;
  margin: 0;
  /* font-weight: 600; */
  color: #707070;
  font-size: 0.75rem;
  font-family: Verdana !important;
}

.dynamic-banner img {
  object-fit: cover;
}

/* .home-footer_new {
  margin-top: 170px;
} */

.row.pt-5.calendar-content .rbc-row.rbc-time-header-cell .rbc-button-link span {
  font: normal normal bold 18px/22px Verdana !important;
}

.row.pt-5.calendar-content .rbc-row.rbc-time-header-cell .rbc-header {
  text-overflow: unset !important;
  white-space: unset;
  overflow: visible;
  background-color: var(--color-element-1);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row.pt-5.calendar-content .rbc-row.rbc-time-header-cell .rbc-button-link {
  width: 50px;
}

.row.pt-5.calendar-content .rbc-row.rbc-time-header-cell {
  min-height: 70px;
}

/* Ends Date picker calandar styling*/

.row.pt-5.calendar-content .rbc-toolbar button {
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important;
  color: #1a3a4c;
  border: 3px solid;
  background-color: #1a3a4c00;
  border-image: linear-gradient(
      to right,
      var(--color-element-1),
      var(--color-element-2)
    )
    1;
  margin-right: 5px;
  border-radius: 1px;
  font: normal normal bold 14px/17px Verdana;
  color: #1a3a4c;
}

.row.pt-5.calendar-content .rbc-toolbar button.rbc-active {
  background-image: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important;
  background-clip: border-box;
  color: #fff;
}

.row.pt-5.calendar-content .rbc-toolbar button:hover {
  background-image: linear-gradient(
    90deg,
    var(--color-element-1),
    var(--color-element-2)
  ) !important;
  background-clip: border-box;
  color: #fff;
}

.rbc-day-bg {
  background: #dbd8d6 !important;
}

.rbc-row.rbc-time-header-cell .rbc-header {
  text-overflow: unset;
  white-space: unset;
  overflow: visible;
}

.rbc-row.rbc-time-header-cell {
  min-height: 70px !important;
}

.rbc-row.rbc-time-header-cell .rbc-button-link {
  font: normal normal bold 18px/22px Verdana;
  white-space:nowrap;
}

.rbc-day-bg.rbc-today {
  border: 2px dashed #f5871f !important;
}

.calscroll .mb-2 {
  margin-bottom: 1.775rem !important;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #fff !important;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #fff !important;
}

.rbc-off-range-bg {
  background: #aaa9aa !important;
}

/* .rbc-time-view {
  margin-top: 1.775rem;
} */
.rbc-day-slot.rbc-time-column {
  background-color: #dbd8d6;
}

.rbc-day-slot.rbc-time-column:nth-child(2),
.rbc-row-bg .rbc-day-bg:nth-child(1),
.rbc-day-slot.rbc-time-column:nth-child(8),
.rbc-row-bg .rbc-day-bg:nth-child(7) {
  background-color: #aaa9aa !important;
}

.rbc-today {
  background-color: rgb(245 135 31 / 53%) !important;
  border: 2px dashed #f5871f;
}

.row.pt-5.calendar-content .rbc-header + .rbc-header {
  border-left: 1px solid #fff;
}

.row.pt-5.calendar-content .rbc-time-content > * + * > * {
  border-left: 1px solid #fff;
}

.row.pt-5.calendar-content .rbc-time-content {
  border-top: 2px solid #fff;
}

.rbc-time-content {
  border: 0px !important;
}

.react-calendar__tile--active {
  background: transparent !important;
  color: #1a3a4c !important;
}

.react-calendar__tile {
  position: relative;
  font-family: Verdana;
}

.react-calendar button {
  font-family: Verdana;
}

.react-calendar__tile--active:before {
  background: #febe10;
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 100%;
}

.react-calendar__tile--active abbr,
.react-calendar__tile abbr {
  position: relative;
  line-height: 25px;
  font-size: 16px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: transparent !important;
}

.react-calendar__tile::before {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 100%;
}

.react-calendar__tile:enabled:hover:before,
.react-calendar__tile:enabled:focus::before {
  background-color: #e6e6e6 !important;
}

.react-calendar {
  background: rgb(170 169 170 / 10%) !important;
  border: 1px solid #fff !important;
  font-family: "Verdana" !important;
}

.react-calendar__month-view__weekdays {
  text-transform: capitalize !important;
  font: normal normal bold 10px/13px Verdana !important;
  letter-spacing: 0.21px;
  color: #1a3a4c;
}

.react-calendar__navigation {
  margin-bottom: 0.1em !important;
}

.myComingEvent {
  height: 60px;

  background: var(--color-element-1) 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  color: #fff;
  font: normal normal bold 18px/60px Verdana;
}

.containerDiv {
  background: #dbd8d6;
  border: 1px solid #ffffff;
  margin-top: 20px;
}

.filled {
  background: #febe10;
}

.myComingEventlist {
  padding: 13px 20px;
  border-bottom: 1px solid #ffffff;
}

.myComingEventlist p {
  margin-bottom: 0;
  font: normal normal normal 10px/12px Verdana;
  letter-spacing: 0.21px;
  color: #1a3a4c;
}

.myComingEventlist b {
  font: normal normal bold 10px/12px Verdana;
  letter-spacing: 0.21px;
  color: #1a3a4c;
}

.myComingEventlist:last-child {
  border-bottom: none;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #fff;
}

.calendar-content .rbc-header {
  padding: 20px 15px;
  background: var(--color-element-1);
  color: #fff;
}

.react-calendar__navigation {
  background: var(--color-element-1);
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--color-element-1) !important;
}

/*# sourceMappingURL=react-big-calendar.css.map */
.modal-show {
  display: block;
}

.modal-hide {
  display: none;
}

/*React Big calandar ends here*/

/* Edit Profile changes */
.role_head {
  font-weight: 600;
}

.fload_right_wishlist {
  float: right;
  margin-top: 0.5rem;
}

.wishlist_btn {
  width: 164px !important;
  font-size: 14px !important;
}

.image-cart .carousel-item {
  position: relative;
}

.rbc-time-header.rbc-overflowing {
  margin-right: 0 !important;
}
.rbc-time-content::-webkit-scrollbar {
  width: 0px;
}
/* .rbc-time-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.rbc-time-content::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
} */
/* Video listing page*/
.lear_iconRP {
  position: absolute;
  left: 35%;
  top: 38%;
  border-radius: 0.313rem;
  padding: 0.125rem 0.625rem;
  color: #fff;
  font-size: 0.75rem;
}

.learn_image-card img {
  /* width: 100%;
  border-radius: 4px 4px 0px 0px; */
  /* height: 12.5rem; */
  object-fit: cover;
}

.rbc-allday-cell {
  display: none;
}

/* Video listing page player css*/

/* .video-js {
  height: 351px !important;
  width: 454px !important;
} */
.img-videosec .video-js {
  height: 100% !important;
  width: 100% !important;
}

.video-js .vjs-time-control {
  display: block !important;
}

.video-js .vjs-remaining-time {
  display: none !important;
}

.video-js {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  border-radius: 4px;
}

.video-js .vjs-time-control {
  padding-left: 0em !important;
  padding-right: 0em !important;
}

.video-js .vjs-big-play-button {
  height: 1.5em !important;
  width: 2em !important;
}

--------------- .video-js .vjs-duration,
.vjs-no-flex .vjs-duration {
  display: block;
}

.video-js .vjs-current-time,
.vjs-no-flex .vjs-current-time {
  display: block;
}

.video-js .vjs-remaining-time,
.vjs-no-flex .vjs-current-time {
  display: none;
}

.vjs-live .vjs-time-control {
  display: block;
}

.vjs-time-divider {
  display: block;
}

/* .img-videosec .video-js {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
/* padding-top: 25px; */
/* height: 0; */
/* } */

.error-msg {
  font-size: "10px";
  color: red;
  margin-top: -15px;
}

.note-display-hide {
  display: none;
}

.note-display-show {
  display: block;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #fff !important;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #fff !important;
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  position: absolute !important;
  left: auto !important;
  top: 9px !important;
  right: 5px !important;
}

/* animation */
/* congratulations popup */
.congrtsmodal .modal-content {
  box-shadow: 0px 6px 24px #00000066;
  border-radius: 20px;
}

.congratulationmodal.modal-body h4 {
  font: normal normal bold 24px/29px Verdana;
  letter-spacing: 0px;
  color: #00adbf;
  text-align: center;
  margin-bottom: 16px;
}

.congratulationmodal.modal-body {
  padding: 30px;
}

.congrtsbox {
  text-align: center;
  font: normal normal normal 20px/24px Verdana;
}

.countno {
  font: normal normal bold 48px/58px Verdana;
  letter-spacing: 0px;
  color: #00adbf;
}

.lerpoints {
  font: normal normal bold 15px/19px Verdana;
  letter-spacing: 0px;
  color: #00adbf;
}
.congrtsmodal .countbox {
  text-align: center;
  padding: 0 15px;
}

.timeboxde span {
  width: 70%;
}

.timeboxde .side-image {
  width: 30%;
  margin: 0;
}

.timeboxde {
  gap: 10px;
  margin-bottom: 10px;
}
.counteranime span {
  font: normal normal bold 48px/58px Verdana !important;
}
.counmainbx {
  height: 7rem;
}
.zoom {
  position: fixed;
  width: 120px;
  opacity: 0;
  animation: zoom 1.5s linear forwards;
  z-index: 1;
}

.earned_badge .lerpoints {
  word-break: break-word;
  height: 2.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@keyframes zoom {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.9;
    top: 30%;
    right: 30%;
  }
  50% {
    opacity: 0.7;
    top: 20%;
    right: 25%;
  }
  70% {
    opacity: 0.5;
    top: 12%;
    right: 20%;
  }
  90% {
    opacity: 0.8;
    top: 9%;
    right: 12%;
  }
  100% {
    opacity: 0;
    top: 7%;
    right: 6%;
  }
}

/* .avatar{
  position: relative;
}
.avatar::before, .avatar::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #00ADBF;
  z-index: -1;
  animation: glow 1.3s ease 2;
}
.avatar::after {
  z-index: -2;
  animation-delay: 1s;
}
@keyframes glow {
  100% {
    transform: scale(1.3);
    opacity: 0;
 }
} */
.canvasblast {
  z-index: 99999;
}

.step {
  border: 0px solid #00adbf;
  color: #fff;
  animation: pulse 1.3s 2;
  border-radius: 100%;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 5px #00adbf;
  }
  100% {
    box-shadow: 0 0 0 5px transparent;
  }
}

.earned_badge img {
  margin-bottom: 0.5rem;
}

.earned_badge {
  width: 60%;
}

.zoom .countno,
.zoom .counteranime span {
  font-size: 18px !important;
  line-height: 22px !important;
}
.zoom .lerpoints {
  font-size: 12px !important;
  line-height: 12px;
}
.zoom .earned_badge img {
  width: 30px !important;
}

.no-graph-data {
  width: 80%;
  justify-content: center;
  margin: 0 auto;
}
.no-graph-data img {
  max-width: 100%;
  width: 118px;
  height: 118px;
}
.legends-data ul {
  list-style: none;
  padding: 0;
}

.colord {
  height: 12px;
  width: 12px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 12px;
  display: inline-block;
}

.legends-data ul li {
  font-weight: 600;
  padding-left: 5px;
  font-size: 12px !important;
  margin-bottom: 5px;
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(55, 61, 63);
}

.legends-data {
  border-left: 1px solid #c7c4c1;
  width: calc(100% - 150px);
  padding-left: 3.125rem;
  display: flex;
  align-items: center;
  margin-left: 3.125rem;
}

/* Tool box css */
.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  background: #00c706 !important;
}
button.react-fine-uploader-gallery-retry-button {
  display: none !important;
}


.input__field.passwordforgt_input {
margin-bottom: 0px;
}

.btn-procced-agree.btn-primary:disabled {
  opacity: 0.4;
  }
.checklist-alert {
    top: 0;
    position: relative;
}
.checklist-alert .shop-cart {
  margin-top: 0;
}
.learnigpathsectio .my_courses .nav-item {
  width: 20%;
}
.table-responsive1 .addBGcustom td.review-date {
  padding-right: 16px;
}