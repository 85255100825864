:root {
    --color-element-1:#00b7f1;
  --color-element-2:#ed156e ;
  }
.button-create {
    border: 2px solid #AAA9AA;
    font-size: 14px;
    padding: 8px 25px;
    font-family: Verdana;
    color: #AAA9AA;
    font-weight: bold;
    border-radius:2px;
}
.learning-pg {
    margin-top: 32px;
}
.learn-head {
    display: flex;
    justify-content: flex-start;
    margin-top:50px;
}
.head-title {
    font-family: 'Verdana';
    color: #000;
    font-size: 26px;
    font-weight: bold;
    margin-right: 24px;
    margin-bottom: 20px;
    text-transform: capitalize;
}
.btn-edit-learn {
    padding:6px 33px;
    border:2px solid #AAA9AA;
    font-family: Verdana;
    font-weight:bold;
    font-size:14px;
    color: #AAA9AA;
    border-radius:2px;
}
.container-chart .box .chart-7 {
    position: relative;
    width: 60px !important;
    height: 100% !important;
    text-align: center;
    font-size: 30px;
    line-height: 50px;
    height: 75px;
    color: #1A3A4C;
    /* padding: 23px 0px; */
    font-family: 'museo700';
    margin-right:10px;
}
.container-chart .box span {
    color: #000;
    font-weight: bold;
    font-size: 10px;
    font-family: Verdana;
}
.container-pie .chart-content {
    font-family: Verdana;
    font-size: 12px;
    font-weight: bold;
    color: #000;

}
.learn-content p {
    font-family: Verdana;
    font-size: 14px;
    font-weight: normal;
    color: #000;
    text-align: left;
}
.profile-left-sec.learning {
    background-color: #F7F7F7;
    margin-bottom: 200px;
    /* padding: 0 139px 0 139px; */
}
.container-chart .box .chart-9 {
    position: relative;
    width: 60px !important;
    height: 100% !important;
    text-align: center;
    font-size: 30px;
    line-height: 50px;
    height: 75px;
    color: #1A3A4C;
    /* padding: 23px 0px; */
    font-family: 'museo700';
    margin-right:10px;
}
.learn-head-learning {
    display: flex;
    justify-content: flex-start;
    margin-top:40px;
}
.btn:focus {
    box-shadow: none !important;
}
/* .slick-slide {
    width: 260px !important;
} */
.slick-dots {
    background: unset !important;
    height:auto;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 37px !important;
    height: 7px !important;
    border-radius: 5px;
    padding: 3px !important;
    cursor: pointer;
    /* color: black; */
    border: 2px solid #000 !important;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: -8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px !important;
    height: 7px !important;
    content: " " !important;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 35px !important;
    height: 10px !important;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    border-radius: 5px;
}
li.slick-active {
background: transparent linear-gradient(90deg, var(--unnamed-color-00b7f1) 0%, var(--unnamed-color-ed156e) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(90deg, var(--color-element-1) 0%, var(--color-element-2) 100%) 0% 0% no-repeat padding-box;
}
.slick-slide {
    opacity: 1 !important;
}
.CircularProgressbar .CircularProgressbar-text {
    font-size: 1rem !important;
    font-family: Verdana !important;
    color: #000;
    font-weight: bold;
}
.slick-active {
    opacity: 1 !important;
}
.slick-dots {
    position: unset !important;
}